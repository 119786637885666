import Form from '@rjsf/material-ui'
import React, { useState, Fragment, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Button, Fab,MenuItem, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Tooltip, Divider, List, ListItem, ListItemText, Typography, Grid, Paper, Box, TextField, Stepper, Step, StepButton, StepContent, IconButton, Menu, Select, InputLabel, Chip, Input, FormControl } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import axios from 'axios';
import { AppBarHeaderDummy } from "../components/AppBarIntegrator";
import { makeStyles } from '@material-ui/core/styles';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Controlled as CodeMirror2 } from 'react-codemirror2';
import 'codemirror/theme/darcula.css';

import { BuildRounded, ExpandMoreRounded, OfflineBoltRounded, SettingsRounded, SpellcheckRounded, VpnKeyRounded } from '@material-ui/icons';
require('codemirror/mode/python/python')


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


Function.deserialise = function (key, data) {
    return (data instanceof Array && data[0] == 'window.Function') ?
        new (Function.bind.apply(Function, [Function].concat(data[1], [data[2]]))) :
        data
        ;
};

Function.prototype.toJSON = function () {
    var whitespace = /\s/;
    var pair = /\(\)|\[\]|\{\}/;

    var args = new Array();
    var string = this.toString();

    var fat = (new RegExp(
        '^\s*(' +
        ((this.name) ? this.name + '|' : '') +
        'function' +
        ')[^)]*\\('
    )).test(string);

    var state = 'start';
    var depth = new Array();
    var tmp;

    for (var index = 0; index < string.length; ++index) {
        var ch = string[index];

        switch (state) {
            case 'start':
                if (whitespace.test(ch) || (fat && ch != '('))
                    continue;

                if (ch == '(') {
                    state = 'arg';
                    tmp = index + 1;
                }
                else {
                    state = 'singleArg';
                    tmp = index;
                }
                break;

            case 'arg':
            case 'singleArg':
                var escaped = depth.length > 0 && depth[depth.length - 1] == '\\';
                if (escaped) {
                    depth.pop();
                    continue;
                }
                if (whitespace.test(ch))
                    continue;

                switch (ch) {
                    case '\\':
                        depth.push(ch);
                        break;

                    case ']':
                    case '}':
                    case ')':
                        if (depth.length > 0) {
                            if (pair.test(depth[depth.length - 1] + ch))
                                depth.pop();
                            continue;
                        }
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        state = (fat) ? 'body' : 'arrow';
                        break;

                    case ',':
                        if (depth.length > 0)
                            continue;
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        tmp = index + 1;
                        break;

                    case '>':
                        if (depth.length > 0)
                            continue;
                        if (string[index - 1] != '=')
                            continue;
                        if (state == 'arg')
                            throw '';
                        args.push(string.substring(tmp, index - 1).trim());
                        state = 'body';
                        break;

                    case '{':
                    case '[':
                    case '(':
                        if (
                            depth.length < 1 ||
                            !(depth[depth.length - 1] == '"' || depth[depth.length - 1] == '\'')
                        )
                            depth.push(ch);
                        break;

                    case '"':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '"')
                            depth.pop();
                        break;
                    case '\'':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '\'')
                            depth.pop();
                        break;
                }
                break;

            case 'arrow':
                if (whitespace.test(ch))
                    continue;
                if (ch != '=')
                    throw '';
                if (string[++index] != '>')
                    throw '';
                state = 'body';
                break;

            case 'body':
                if (whitespace.test(ch))
                    continue;
                string = string.substring(index);

                if (ch == '{')
                    string = string.replace(/^{\s*(.*)\s*}\s*$/, '$1');
                else
                    string = 'return ' + string.trim();

                index = string.length;
                break;

            default:
                throw '';
        }
    }

    return ['window.Function', args, string];
};

const useStyles = makeStyles((theme) => ({
    root: {
       display: 'flex',
        paddingRight: '12px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.default,
        paddingRight: 20,
        marginTop: 50
    },
    fab: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9
    },
}));





export default () => {
    const classes = useStyles();

    const [allApps, setAllApps] = useState([])
    const [loadingAllApps, setLoadingAllApps] = useState(false)
    const [alert, setAlert] = useState();
    const [appInfo, setAppInfo] = useState({ actions: {}, triggers: {}, authInfo: {} })
    const [loadingApp, setLoadingApp] = useState(false)

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [formType, setFormType] = useState(null)
    const [selectedObject, setSelectedObject] = useState(null)

    const statusOptions = [
        "Complete",
        "In Progress"
    ]

    const allAppsFilterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 50
    })

    console.log(appInfo)

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const loadApps = () => {
        setLoadingAllApps(true)
        axios({
            method: "get",
            url: "https://editor.workload.co:5000/api/apps"
        }).then(resp => { console.log(resp); setAllApps(resp.data); setLoadingAllApps(false) })
    }
    const pathname = window.location.pathname.split("/")[2]
    useEffect(() => {
        console.log('pathname is: ', pathname)
       if (pathname) {
        loadApp(pathname)
       }
    }, [pathname])
console.log('this is the app overview page talking MYG!')
    const loadApp = (appSlug) => {
        setLoadingApp(true)
        setSelectedObject(null)
        setFormType(null)
        setAppInfo({ actions: {}, triggers: {}, authInfo: {} })
        axios({
            method: 'get',
            url: `https://editor.workload.co:5000/api/apps/`+appSlug
        }).then(resp => { setAppInfo(resp.data); setLoadingApp(false); setDisabled(false) })
    }

    useEffect(() => {
        loadApps()
    }, [])


   
    const makeNewKey = (dictOfKeys, testString, num = 0) => {
        if (Object.keys(dictOfKeys).includes(testString)) {
            return makeNewKey(dictOfKeys, testString.slice(0, testString.length - 1) + num, num + 1)
        } else {
            return testString
        }
    }

   
    
    const history = useHistory()
    console.log('appInfo is::: ', appInfo)
    const [seoApp, setSeoApp] = useState()
    const [seoValues, setSeoValues] = useState()

    const appname = window.location.pathname.split("/")[2]
    useEffect(() => {
        if (appname !== undefined) {
        // get data from api
        fetch("https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/seo?app="+appname)
        .then(response => response.json()) // parse JSON from request
          .then(resultData => {
            console.log('result of seo: ', resultData)
            setSeoApp(resultData[0])
            
          })
      }}, [appname])

    const handleSubmitSeoValues = () => {
        setSaving(true)
        if (seoApp?._id) {
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'put',
            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/seo?id='+seoApp._id.$oid,
            data: JSON.stringify(seoValues)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" }); handleClick(); setSaving(false) })
        .catch(function (error) {console.log('error big time...', error)})
    } else {
        console.log('no app found, lez create one...')
        var appSlug = {app: pathname}
        var seoV = seoValues
        const data = {...appSlug, ...seoV}
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/seo',
            data: JSON.stringify(data)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" }); handleClick(); setSaving(false) })
        .catch(function (error) {console.log('error big time...', error)})
        
       

    }
   
    }

    useEffect(() => {
        const close = document.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
        )[0];
    
        // Add a Click Event Listener to the button
        close.addEventListener("click", () => {
            history.push("/develop")
        });
      }, []); 

    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <AppBarHeaderDummy />
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid style={{overflow: 'auto'}} item xs={4} >
                        <Paper className={classes.paper} style={{ marginLeft: 15 }}> <Typography ><h1>Apps</h1></Typography>
                            <Button variant='outlined' style={{marginBottom: '10px'}} color='primary' onClick={() => { history.push("/develop/new") }}>Add App</Button>
                            <Autocomplete
                                loading={loadingAllApps}
                                loadingText={"Loading Apps..."}
                                noOptionsText={"No Apps? Something has gone wrong..."}
                                options={allApps}
                                getOptionLabel={(option) => option.appName ?? option.appSlug}
                                onChange={(e, v) => {
                                   // setAppInfo({ ...v, actions: {}, triggers: {}, authInfo: {} })
                                    if (v) {
                                        loadApp(v.appSlug);
                                        history.push("/develop/"+v.appSlug)

                                    } else {
                                        setDisabled(true)
                                    }
                                }}
                                value={appInfo ?? { appSlug: '' }}
                                renderInput={(params) => <TextField style={{ width: '100%', padding: '4px', margin: '4px' }} {...params} variant="outlined" placeholder={"Type to search apps"} label={"App"} />}
                                filterOptions={allAppsFilterOptions}
                            />
                            {
                                appInfo.appSlug ?
                                    <>
                                        <Autocomplete
                                            options={statusOptions}
                                            onChange={(e, v) => {
                                                setAppInfo({ ...appInfo, completionStatus: v })
                                            }}
                                            value={appInfo.completionStatus}
                                            renderInput={(params) => <TextField variant="outlined" style={{margin: '4px', width: '100%', padding: '4px' }} {...params} label={"Status"} />}
                                        />
                                        <TextField style={{margin: '4px', width: '100%', padding: '4px' }} label={"API Docs"} value={appInfo.apiDocs} onChange={(e) => { setAppInfo({ ...appInfo, apiDocs: e.target.value }) }} />
                                        <Box style={{width: '100%', height: '700px', overflow: 'scroll' }}>
                                        <MenuItem style={{margin: '8px 0px 0px 0px', color: '#0069ea', backgroundColor: '#f0f1fa'}}><SettingsRounded style={{marginRight: '10px', padding: '4px'}}/><Typography variant="h6">App Overview</Typography></MenuItem>
                                        <Accordion elevation={0} defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreRounded />}><BuildRounded style={{marginRight: '10px', padding: '4px'}} /><Typography variant="h6">Develop</Typography></AccordionSummary>
                                            <AccordionDetails>
                                                <List>
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/authentication")} style={{margin: '0px 0px 4px 0px'}}>Authentication</MenuItem>
                                                    {appInfo.authInfo.apiType && (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/authentication/edit")}><VpnKeyRounded style={{marginRight: '8px'}} />{appInfo.authInfo.apiType === 'basic' &&
                                                       <Typography variant="body1">Basic Auth</Typography>}
                                                       {appInfo.authInfo.apiType === 'apikey' &&
                                                       <Typography variant="body1">API Key</Typography>}
                                                       {appInfo.authInfo.apiType === 'oauth2' &&
                                                       <Typography variant="body1">OAuth 2.0</Typography>}
                                                       {appInfo.authInfo.apiType === 'customCode' &&
                                                       <Typography variant="body1">Custom</Typography>}</MenuItem>  
                                                    )}
                                                    {appInfo.disableAuth === true && (
                                                       <MenuItem disabled><VpnKeyRounded style={{marginRight: '8px'}} /><Typography variant="body1">{"Passport App"}</Typography></MenuItem>  
                                                    )}
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/triggers")} style={{margin: '12px 0px 4px 0px'}}>Triggers</MenuItem>
                                                    {Object.values(appInfo.triggers).map(trigger => (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/triggers/"+trigger.key+"/settings")}><OfflineBoltRounded style={{marginRight: '8px'}} /><Typography variant="body1">{trigger.label}</Typography></MenuItem> 
                                                    ))}
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/actions")} style={{margin: '12px 0px 4px 0px'}}>Actions</MenuItem>
                                                    {Object.values(appInfo.actions).map(action => (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/actions/"+action.key+"/settings")}><SpellcheckRounded style={{marginRight: '8px'}} /><Typography variant="body1">{action.title}</Typography></MenuItem> 
                                                    ))}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        </Box>
                                    </> :
                                    null
                            }
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={8} >
                    <Paper className={classes.paper}> 
                    <Typography ><h1>App Integration Overview</h1></Typography>
                    <InputLabel><Typography variant="h6">* Integration Name (required)</Typography></InputLabel>
                    <TextField
                    style={{width: '100%'}}
                        id="outlined-helperText"
                        //label="* Integration Name (required)"
                        defaultValue={seoApp && seoApp.name}
                        value={seoValues?.name ?? seoApp?.name ?? ""}
                        onChange={(e) => setSeoValues({name: e.target.value})}
                        helperText="The name of your integration as it is displayed on the Workload integrations page."
                        variant="outlined"
                        />  <br/><br/><br/>
                     <InputLabel><Typography variant="h6">* Integration Description (required)</Typography></InputLabel>
                    <TextField
                    style={{width: '100%'}}
                        id="outlined-helperText"
                        defaultValue={seoApp && seoApp.description}
                        value={seoValues?.description ?? seoApp?.description ?? ""}
                        onChange={(e) => setSeoValues({description: e.target.value})}
                        helperText="Maximum 160 chars. Ex: 'Trello is a tream collaboration tool to organize tasks and keep projects on track.'"
                        variant="outlined"
                        multiline
                        rows={3}
                        />  <br/><br/><br/>
                    <InputLabel><Typography variant="h6">* Integration homepage URL (required)</Typography></InputLabel>
                     <TextField
                     style={{width: '100%'}}
                        id="outlined-helperText"
                        defaultValue={seoApp && seoApp.homepage}
                        value={seoValues?.homepage ?? seoApp?.homepage ?? ""}
                        onChange={(e) => setSeoValues({homepage: e.target.value})}
                        helperText=""
                        variant="outlined"
                        />  <br/><br/><br/>
                        <Box display="flex">
                            <Box component="img"
                            src={seoApp && seoApp.icon}
                            flex="10%"
                            style={{maxHeight: '75px', maxWidth: '75px', justifyContent: 'center', marginTop: '25px', marginRight: '12px'}}
                             />
                            <Box flex="70%">
                    <InputLabel><Typography variant="h6">* App Logo (required)</Typography></InputLabel>
                     <TextField
                     style={{width: '100%'}}
                        id="outlined-helperText"
                        defaultValue={seoApp && seoApp.icon}
                        value={seoValues?.icon ?? seoApp?.icon ?? ""}
                        onChange={(e) => setSeoValues({icon: e.target.value})}
                        helperText="Upload a square, 256x256px or larger transparent PNG image that does not include the app name. "
                        variant="outlined"
                        /> </Box></Box> <br/><br/><br/>
                     <InputLabel><Typography variant="h6">* App Category (required)</Typography></InputLabel>
                     <TextField
                     style={{width: '100%'}}
                        id="outlined-helperText"
                        
                        defaultValue={seoApp && seoApp.category}
                        value={seoValues?.category ?? seoApp?.category ?? ""}
                        onChange={(e) => setSeoValues({category: e.target.value})}
                        helperText="Choose the most appropriate option for your app’s core features."
                        variant="outlined"
                        />  <br/><br/><br/>
                      
                    <InputLabel><Typography variant="h6">* Publish Status (required)</Typography></InputLabel>
                    <TextField
                    select
                     style={{width: '100%'}}
                        id="outlined-helperText"
                        //defaultValue={seoApp && seoApp.status}
                        //value={seoValues.status ?? seoApp?.status}
                        value={""}
                        onChange={(e) => setSeoValues({status: e.target.value})}
                        helperText="Choose the most appropriate option for your app’s core features."
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                seoValues?.status ?? seoApp?.status ?
                              <Chip label={seoValues?.status ?? seoApp?.status ?? ""} color="primary" /> : null
                            ),
                          }}
                        > 
                        <MenuItem key={"published"} value={"Published"} >Published</MenuItem>
                        <MenuItem key={"beta"} value={"Beta"} >Beta</MenuItem>
                        <MenuItem key={"coming-soon"} value={"Coming Soon"} >Coming Soon</MenuItem>
                        </TextField>
                        <br/><br/><br/>
                        <div style={{marginRight: '0px', padding: '20px'}}>
            <Button onClick={()=> handleSubmitSeoValues()} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
            </div><div style={{marginRight: '40px', width: '100%', padding: '20px'}}></div>
                    </Paper>
                    </Grid>
                     

                    
                    
                    
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alert ? alert.severity : null}>
                            {alert ? alert.message : null}
                        </Alert>
                    </Snackbar>
                   
                </Grid>
            </div></div>

    )
}
