import axios from 'axios';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";

import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core'
import { AppBarHeader } from "./components/AppBar";
import { AppBarHeaderDummy } from "./components/AppBarDummy";
import { Fab, Card, Tooltip, Snackbar, Drawer, TextField, Button, Modal } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import ActionPanel from './components/NodePanels/ActionPanel'
import MobileStepper from '@material-ui/core/MobileStepper';
import TriggerPanel from './components/NodePanels/TriggerPanel'
import FilterPanel from './components/EdgePanels/FilterPanel';
import CreateTriggerPanel from './components/EdgePanels/CreateTriggerPanel';
import CreateSupportTicketPanel from './components/EdgePanels/CreateTicketPanel';
import ReactFlow, { ReactFlowProvider,useStore, removeElements, addEdge, Background, Controls,MiniMap, isNode, useZoomPanHelper } from 'react-flow-renderer';
import { v4 as uuid } from 'uuid'
import ActionNode from './components/CustomNodes/ActionNode'
import TriggerNode from './components/CustomNodes/TriggerNode'
import FilterEdge from './components/CustomEdges/FilterEdge'
import ConnectionLine from './components/ConnectionLine'
import { Alert, AlertTitle } from '@material-ui/lab';



import {
    nodeAdded, edgeAdded, elementUpdated, selectNotification,notification, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected,
    selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplateNodes, selectWorkflowActive, selectCurrentElement, createWorkflow, createTemplate0, createTemplate5, createTemplate1, createTemplate2,createTemplate4, acceptTemplateNew,
    acceptTemplateExist
} from './features/workflow/workflowSlice'
import { selectWorkflowID } from './features/execution/executionSlice';
import { setTokenSourceMapRange } from 'typescript';
import { SentimentVerySatisfied, SettingsVoiceRounded } from '@material-ui/icons';


const modalheight = window.innerHeight;
const modalwidth = window.innerWidth;

function getModalStyle() {
    const top = 5
    //const left = 50 + rand();

    return {
        top: `${top}%`,
        margin: 'auto'
      //  left: `${left}%`,
      //  transform: `translate(-${top}%, -${left}%)`,
    };
}

let breakpointZoom = ''

if (modalwidth < 450) {
    breakpointZoom = 0
} else if (modalwidth < 720 && modalwidth > 449) {
    breakpointZoom = 1
} else if (modalwidth > 721) {
    breakpointZoom = 1
}



let breakpointPosition = ''

if (modalwidth < 450) {

    var breakpointPositionWidth = modalwidth / 2 - 225
    breakpointPosition = [breakpointPositionWidth, 20]

} else if (modalwidth < 720 && modalwidth > 390) {

    var breakpointPositionWidth = modalwidth / 2 - 425
    breakpointPosition = [breakpointPositionWidth, 20]

} else if (modalwidth > 721) {

    var breakpointPositionWidth = modalwidth / 2 - 625
    breakpointPosition = [breakpointPositionWidth, 20]

}



const useStyles = makeStyles((theme) => ({
    fab: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9
    },
    modal: {
        position: 'absolute',
        width: '350px',
        //height: modalheight * .85,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'scroll'
    },

    modalSmall: {
        position: 'absolute',
        width: '66%',
       
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'scroll'
    },
    modaltour: {
        position: 'absolute',
        width: '50%',
        height: modalheight * .75,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'scroll'
    },
    modalverify: {
        position: 'absolute',
        width: '50%',
        height: '40%',
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflowY: 'scroll'
    },
    drawerAnimate: {

        animationName: '$fade',
    },
    '@keyframes fade': {
        '0%': { opacity: '0' },
        '100%': { opacity: '1' },
    },

}));




const nodeTypes = {
    action: ActionNode,
    trigger: TriggerNode
};

const edgeTypes = {
    filter: FilterEdge
}

export default ({ workflowID = null }) => {

    // const [workflow, setWorkflow] = useState(null)
    // const [elements, setElements] = useState([{ id: uuid(), type: 'trigger', position: { x: 200, y: 200 }, data: { name: 'Click to Add Trigger' } }])
    const selectedElementID = useSelector(selectCurrentElement)
    const selectedElement = useSelector(selectElementById(selectedElementID))
    // const [parentTree, setParentTree] = useState({})

    const [openalert, setOpenAlert] = React.useState(false);
    //console.log(workflow)
    //console.log(elements)
    //console.log(convertToBackendNodes(elements))
    const [source, setSource] = useState(null);
    const sourceRef = useRef();
    const [target, setTarget] = useState(null);
    const targetRef = useRef();
    const [stepIndex, setStepIndex] = useState(0)
    const dispatch = useDispatch();
    const name = useSelector(selectWorkflowName)
    const elements = useSelector(selectWorkflowElements)
    const workflow = useSelector(selectWorkflowTemplateNodes)
    const active = useSelector(selectWorkflowActive)
    
    const [modalStyle] = React.useState(getModalStyle);
    const [modalopen, setModalOpen] = useState(false);
    const [modalopentour, setModalOpenTour] = useState(false)
    const [modalopencodeverify, setModalOpenCodeVerify] = useState(false)
    const notify = useSelector(selectNotification)
  
   
  useEffect(() => {
   var tourReady = localStorage.getItem('tourReady')
   var template = localStorage.getItem('template')
   var visitCount = localStorage.getItem('visitCount')

   if (modalwidth > 1136 && visitCount < '4' && template === '5' && tourReady !== 'false') {
    //toggle this to turn the tour on or off
    setModalOpenTour(false)
   } else if (tourReady === 'false') {
       setModalOpenTour(false)
       //console.log('tourReady is: ', tourReady)
   }
  }, [])


const [openstep, setOpenStep] = useState(true)
  useEffect(() => {
    if (openstep === true && elements.length > 0 && workflow) {
 
        const workflowobj = Object.entries(workflow)
      //  console.log('useEffect Elements are, ', workflowobj.slice(-1))
      //  console.log('Elements length are, ', elements.length)
      //  console.log('Workflow length are, ', workflow)
      const filtered = workflowobj.filter(step => {
        const stepobj = step[1].outputFields
        const isEmpty = Object.keys(stepobj).length === 0;
        
        return isEmpty === true;
      });
      // 👇️ [{id: 1, name: 'Alice', country: 'Canada'},
     
      console.log('filtered: ',filtered);
      if (filtered.length === 0){
        setOpenStep(false)
      } else {
        const x = filtered[0]
        const f = x[1].id
        console.log('element x is: ', x)
    //setSelectedElement(elements.find(item => item.id == f))
        dispatch(elementSelected(f))
        setOpenStep(false)
       
      }
    }

}, [elements])

  const [width, setWindowWidth] = useState(0)
  const [breakpoint, setBreakPoint] = useState('')
 
      
 
   useEffect(() => { 

     updateDimensions();

     window.addEventListener('resize', updateDimensions);
     return () => 
       window.removeEventListener('resize',updateDimensions);
    }, [])

    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    }
  
    useEffect(() => {
        if (width > 721) {
          setBreakPoint('large')
          }
          else if (width < 720 && width > 431) {
           
              setBreakPoint('medium')
          }
          else if (width < 431) {
              setBreakPoint('small')
          }
    }, [width])
    

    const handleModalOpen = () => {
        setModalOpen(true);
        hotjar.event('signup-modal-open');
        mixpanel.track('Signup Modal Open', {

        });
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalCloseTour = () => {
        setModalOpenTour(false);
        localStorage.setItem('tourReady', false)
    };

    const handleModalCloseCodeVerify = () => {
        setModalOpenCodeVerify(false);
        localStorage.setItem('codeverify', false)
    };

    const handleDesktopModalClose = () => {
        setDesktopReminderModal(false);
    };


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let id = query.get("id")
    if (id === null) {
        id = uuid();
     
    }
    const [user, setUser] = useState(query.get("u"));
    const [tenant, setTenant] = useState(query.get("t"))
    const template = query.get("template")
    const template_id = query.get("template_id")
    const app1 = query.get("app1")
    const action1 = query.get("action1")
    const app2 = query.get("app2")
    const action2 = query.get("action2")
    const cook = localStorage.getItem('tok_')

    useEffect(() => {
      if (!user && !template_id) {
        sendLoggedOutToAuthorizer()
      }
    }, [user])
    

    const handleClickAlert = () => {
        setOpenAlert(true);
    };
    useEffect(() => {
        if (notify && notify.status) {
            console.log('alert is: ', notify.status.message)
            handleClickAlert();
        }
    }, [notify])

    useEffect(() => {
        sourceRef.current = source
    }, [source])

    useEffect(() => {
        
        targetRef.current = target
        console.log('targtref now: ', targetRef.current)
    }, [target])

    

    useEffect(() => {
        if (modalwidth > 719){
            
        const script = document.createElement('script');

        script.src = "https://js.hs-scripts.com/6870309.js";
        script.async = true;
        script.id = "hs-script-loader";

        document.body.appendChild(script);
        window.hsConversationsSettings = {
            identificationEmail: localStorage.getItem('email'),
            
         };

        return () => {
            document.body.removeChild(script);
        }
        
    }}, []);
    const [accessDenied, setAccessDenied] = useState(false)
    const [workflowloading, setWorkflowLoading] = useState(false)
    useEffect(() => {
        //get the workflow from the server
        
        if (workflowID) {
            if (user && tenant && id && !cook) { 
               //commenting out for now....
               setWorkflowLoading(false); 
               // setAccessDenied(true) 
             // console.log('fix this...')
            }
           else {
            setWorkflowLoading(false)
            dispatch(fetchWorkflow(workflowID))
        
           }
            
        }
       /*  else if (user && tenant && id) {
            console.log('checking if workflow belongs to tenant: ', tenant)
            console.log('checking on workflow id: ', id)
            setWorkflowLoading(false)
            dispatch(fetchWorkflow(workflowID))
            if (!cook) {
                setWorkflowLoading(false)
                 setAccessDenied(true)
            }
           
           

        } */
        else if (template_id && cook) {
            console.log('user has a cookie and is using a template')
            console.log('template id is: ', template_id)
            mixpanel.track('Share Accepted', {
                'url': 'https://editor.workload.co/?template_id='+template_id,
                'user': user
            });
            setWorkflowLoading(true)
            dispatch(acceptTemplateExist({ template_id: template_id, token: cook }))
        }
        else if (template_id && !cook) {
            console.log('user is not signed up and is using a template')
            console.log('template id is: ', template_id)
            console.log('uuid is: ', id)
            mixpanel.track('Share Accepted', {
                'url': 'https://editor.workload.co/?template_id='+template_id,
                'user': user
            });
            setWorkflowLoading(true)
            dispatch(acceptTemplateNew({ template_id: template_id, scaffoldId: id}))
           
           
        }
        else if (template == 0) {
          //  console.log('load template 0')
          //  console.log('id is going to be: ', id)
          setWorkflowLoading(true)
            dispatch(createTemplate0({ id: id, app: app1,cook: cook ? true : false, token: cook ? cook : null }))
            
           
        } else if (template == 1) {
         //   console.log('load template 1')
          //  console.log('id is going to be: ', id)
          setWorkflowLoading(true)
            dispatch(createTemplate1({ id: id, app: app1, action: action1, cook: cook ? true : false, token: cook ? cook : null }))
            
           
        }
        else if (template == 2) {
          //  console.log('load template 2')
           // console.log('id is going to be: ', id)
          //  console.log(app1 + " and " + app2)
          setWorkflowLoading(true)
            dispatch(createTemplate2({ id: id, app1: app1, app2: app2, cook: cook ? true : false, token: cook ? cook : null }))
          
            
        }
        else if (template == 4) {
           console.log('load template 4')
              console.log('id is going to be: ', id)
              setWorkflowLoading(true)
               dispatch(createTemplate4({ id: id, app1: app1, action1: action1, app2: app2, action2: action2, cook: cook ? true : false, token: cook ? cook : null }))
               
             
           }
        else if (template == 5) {
          //  console.log('load template 5')
            localStorage.setItem('template',5)
           // console.log('id is going to be: ', id)
           setWorkflowLoading(true)
            dispatch(createTemplate5({ id: id, cook: cook ? true : false, token: cook ? cook : null}))
          
        }
        else {
            
                localStorage.setItem('template',5)
                setWorkflowLoading(false)
                mixpanel.identify(user) 
                dispatch(createWorkflow(id))
               
            //console.log('creating createWorklfow for some reason')
            
           
        }
    }, [])

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const updateNode = node => {
        dispatch(elementUpdated(node.id, null, node.position))
    }
   
 
    const handleElementClick = (event, el) => {
        handlePaneClick();
        reactFlowInstance.fitView()
         //console.log('element ' + JSON.stringify(el) + ' clicked')
        
        // setSelectedElement(null)
        // setSelectedElement(elements.find(item => item.id == el.id))
      /*   if (el.type === 'trigger' && stepIndex === 0){
            setStepIndex(stepIndex + 1)
        }

        if (el.type === 'action' && stepIndex === 5){
            setStepIndex(stepIndex + 1)
        } */
        dispatch(elementSelected(el.id))

        hotjar.event('node-click');
        mixpanel.track('Node Selected', {
            'app': el.app,
            'user': user
        });
    
    }
   
    const [createTrigger, setCreateTrigger] = useState(false)
    const [createTriggerApp, setCreateTriggerApp] = useState('')

    const [createSupportTicket, setCreateSupportTicket] = useState(false)
    const [createSupportTicketApp, setCreateSupportTicketApp] = useState('')

    const handlePaneClick = (event) => {
    reactFlowInstance.fitView({padding: .2})
        dispatch(saveWorkflow(1))
        // setSelectedElement(null);
        dispatch(elementSelected(null))
        setCreateTrigger(false)
        setCreateSupportTicket(false)
        // dispatch(deletefilterclick(filterC))
       

    }
    

    
    const handleCreateTrigger = (event) => {
        console.log('event is: ', event)
        handlePaneClick();
        setCreateTrigger(true)
        setCreateTriggerApp(event)
    }

    const handleCreateSupportTicket = (event) => {
        console.log('event is: ', event)
        handlePaneClick();
        setCreateSupportTicket(true)
        setCreateSupportTicketApp(event)
    }

    const handleNodeMove = (event, node) => {
        updateNode(node)
        handleSaveClick();
    }

    const handleonConnectStart = (event, { nodeId, handleType }) => {
        setTarget(null)
        //  console.log('handle connect start event', event)
        // console.log('handle connect start nodeId', nodeId)
        setSource(nodeId);
        // console.log('handle connect start handleType', handleType)
        handleSaveClick();
    }
const [reactFlowInstance, setReactFlowInstance] = useState()

const onLoad = (rfI) => {
   // setReactFlowInstance(rfI)
    rfI.fitView({ padding: 0.1})
    setReactFlowInstance(rfI)
}


     
    
    
    // const {project} = useZoomPanHelper();
    //console.log('reactFlowInstance is, ', reactFlowInstance)
    //const firstelement = reactFlowInstance?.getElements({})
    //console.log('firstelement is, ', firstelement)
    //const fstelmnt = Object.assign({}, firstelement)
    //console.log('fstelmt is, ', fstelmnt[0])
    //const f = fstelmnt[0]
    //console.log('f is, ',f)
    
    //console.log('zoom instance: ', reactFlowInstance)

    
    const handleLineDrop = (event) => {

          console.log('handleLineDrop event is ', event)
        //   console.log('event is LINE DROP ')
        // const rfBounds = rfWrapper.current.getBoundingClientRect();
        const position = reactFlowInstance.project({
            x: event.clientX - 225,
            y: event.clientY + 30
        })
        // var x = event.layerX - 10;
        // var y = event.layerY - 55;
        var x = position.x;
        var y = position.y;
        // console.log('x equals ', x)
        // console.log('y equals ', y)
        // console.log('source' + sourceRef.current)
        // addNode(x, y);
        
        if (event.toElement.dataset.nodeid !== sourceRef.current && event.toElement.dataset.nodeid !== undefined ) {
          //  console.log('target exists: ' + event.toElement.dataset.nodeid)
            
            dispatch(edgeAdded('filter', sourceRef.current, event.toElement.dataset.nodeid ))
            setTarget(null)
            handleSaveClick();
        }
        else {
           // console.log('source exists: ', sourceRef.current)
           
           if (workflow[sourceRef.current] && workflow[sourceRef.current].type === 'polling') {
          console.log('target is now: ', target)
            dispatch(nodeEdgeAdded('action', x+450, y, 'filter', sourceRef.current))
            setSource(null)
            dispatch(notification({"status": "info", "message": "Action step added"}))
            handleSaveClick();
            setFirstLoad(true)
           } else if (workflow[sourceRef.current] && workflow[sourceRef.current].type === 'webhook') {
            dispatch(nodeEdgeAdded('action', x+450, y, 'filter', sourceRef.current))
            setSource(null)
            dispatch(notification({"status": "info", "message": "Action step added"}))
            handleSaveClick();
            setFirstLoad(true)
           } else if (workflow[sourceRef.current] && Object.keys(workflow[sourceRef.current]['connections']).length !== 0) {
            dispatch(nodeEdgeAdded('action', x+450, y, 'filter', sourceRef.current))
            setSource(null)
            dispatch(notification({"status": "info", "message": "Action step added"}))
            handleSaveClick();
            setFirstLoad(true)
           } else if (workflow[sourceRef.current] && Object.keys(workflow[sourceRef.current]['connections']).length === 0) {
            dispatch(nodeEdgeAdded('action', x, y, 'filter', sourceRef.current))
            setSource(null)
            handleSaveClick();
            dispatch(notification({"status": "info", "message": "Action step added"}))
            setFirstLoad(true)
           }
           else {
            dispatch(nodeEdgeAdded('action', x, y, 'filter', sourceRef.current))
            setSource(null)
            handleSaveClick();
            dispatch(notification({"status": "info", "message": "Action step added"}))
            setFirstLoad(true)
           }
        }

    }


    const handleSaveClick = () => {
       
        dispatch(notification({"status": "success", "message": "Your changes were auto-saved..."}))
        //  console.log('save clicked')
        dispatch(saveWorkflow(1))
        hotjar.event('save-click');

    }

    const flowStyle = { background: '#f2f2f2', height: '100vh', width: '100vw', zIndex: 0 }

    const onElementsRemove = (elementsToRemove) => {

        //  console.log('delete ', elementsToRemove);
        // setElements(elements => removeElements([elementsToRemove], elements))
        // handlePaneClick();
        hotjar.event('remove-click');
    }



    const onConnect = (params) => {
      //  console.log('onConnect params are: ', params)
        setTarget(params.target)
     //   dispatch(edgeAdded('filter', params.source, params.target))
        // setElements(els => addEdge({ ...params, animated: true, type: 'filter', style: { stroke: 'gray', strokeWidth: 2 }, data: { text: 'empty', fields: baseFields } }, els))
    }

    const handleKeyDownSave = event => {
        // console.log('console log event ', event)

        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === 's') {
            event.preventDefault();
            handleSaveClick();
            dispatch(notification({ "status": "success", "message": name + " was auto-saved" }))
        } else {
            return;
        }
    }

    //TODO: this
    // const ElementPanel = ({ element }) => {
    //     if (isNode(element)) {
    //         if (element.type == 'action') {
    //             return <ActionPanel selectedNode={element} onPaneClick={handlePaneClick} sendNode={updateNode} deleteNode={onElementsRemove} workflowID={workflow.id} user={user} tenant={tenant} />
    //         } else if (element.type == 'trigger') {
    //             return <TriggerPanel selectedNode={element} onPaneClick={handlePaneClick} sendNode={updateNode} setWFActive={sendWFActive} workflowID={workflow.id} user={user} tenant={tenant} />
    //         }
    //     } else {
    //         return null
    //     }
    // }
    const [firstload, setFirstLoad] = useState(false)

   
    useEffect(() => {
        
        if (elements.length > 0 && firstload === true && workflow) {
           
            const workflowobj = Object.entries(workflow)
          //  console.log('useEffect Elements are, ', workflowobj.slice(-1))
          //  console.log('Elements length are, ', elements.length)
           // console.log('Workflow length are, ', workflow)
            const x = workflowobj.slice(-1)
            const f = x[0][1]?.id
            console.log('f===== ', f)
            console.log('x===== ', x)
        //setSelectedElement(elements.find(item => item.id == f))
            dispatch(elementSelected(f))
            setFirstLoad(false)
        }

    }, [firstload])


    const [run, setTourStart] = useState(false)
    

    const [desktopreminderloading, setDesktopReminderLoading] = useState(false)
    const [desktopReminderModal, setDesktopReminderModal] = useState(false)
    const [deskworkemail, setDeskWorkemail] = useState();
    const [validworkemail, setValidworkemail] = useState(true)
    const handleDeskWorkemailChange = (e) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(e.match(mailformat))
            {
              //.log('matches email address format')
            setValidworkemail(true);
            //handleDesktopReminder();

            }
            else
            {
             //   console.log('does not match email format :(')
            setValidworkemail(false)
            }
        }

    const handleDesktopReminder = () => {
        setDesktopReminderLoading(true)
        let data =  {
            email: deskworkemail,
         }

        let config = {
        method: 'post',
        url: 'https://editor.workload.co:5000/addnewcontact',
        headers: {  
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios(config)
        .then((response) => {
         //   console.log('added to onboarding list')
       // console.log(JSON.stringify(response.data));
        const data2 = JSON.stringify(
            {
                email: deskworkemail,
                href: window.location.href
             }
        )
        let config_send = {
            method: 'post',
            url: 'https://editor.workload.co:5000/sendreminder',
            headers: { 
                
                'Content-Type': 'application/json'
            },
            data : data2
            };
    
            axios(config_send)
            .then((response) => {
          //  console.log(JSON.stringify(response.data));
          //  console.log('sent template email')
        setDesktopReminderLoading(false)
        setDesktopReminderModal(false)
    })
})
    .catch((error) => {
    console.log(error);
    });

    }

    const [pw, setPw] = useState();
    const [pwvalid, setPwvalid] = useState(true);
    const handlePwValid = () => {
            
        if(pw)
        {
          //.log('matches email address format')
        setPwvalid(true);
       // handleModalSignup();

        }
        else
        {
         //   console.log('does not match email format :(')
        setPwvalid(false)
        }
    }
const [signuperror, setSignupError] = useState()
    const handleModalSignup = () => {
        if (pwvalid === false) {
            return;
        }
       
        dispatch(saveWorkflow(1))
        
        setSubmitted(true);
       // console.log('w is: ', workemail);
       // console.log('p is: ', pw);
        hotjar.event('signup-modal-submit');
        mixpanel.track('Modal Signup Submit', {
            'user': workemail
        });
        window.gtag('config', 'AW-11220054099');
        window.gtag('event', 'conversion', {'send_to': 'AW-11220054099/E1nbCOnS5rMYENPgkeYp'});

        var signup = {
            method: 'post',
            url: 'https://backend.workload.co/api/auth/sign-up',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                email: workemail,
                password: pw,
                invitationToken: null,
                emailVerified: true
            }
        }

        axios(signup)
            .then(function (response) {
               // console.log('api token ', response.data);

                const newtoken = response.data;
                localStorage.setItem("tok_", newtoken)
                var me = {
                    method: 'get',
                    url: 'https://backend.workload.co/api/auth/me',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + newtoken
                    }
                }

                axios(me)
                    .then(function (res) {
                     //   console.log('me data ', res.data);
                     //   console.log('user id', res.data.id)
                        const newid = res.data.id
                        mixpanel.identify(newid);

                        mixpanel.people.set({ "email": workemail });
                        localStorage.setItem('user', newid)
                        localStorage.setItem('email', workemail)
                        var workspace = {
                            method: 'post',
                            url: 'https://backend.workload.co/api/tenant',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + newtoken
                            },
                            data: {
                                data: {
                                    name: "My First Workspace"
                                }
                            }
                        }

                        axios(workspace)
                            .then(function (res2) {
                                const newtenant = res2.data.id
                             //   console.log('workspace data ', res2.data);
                                var raw = JSON.stringify({ "data": { "name": "Untitled Workflow (Draft)", "active": false } });
                                var createscaffoldwf = {
                                    method: 'post',
                                    url: 'https://backend.workload.co/api/tenant/' + newtenant + '/workflow',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + newtoken
                                    },
                                    data: raw
                                }

                                axios(createscaffoldwf)
                                    .then(function (res3) {
                                     //   console.log('workspace data ', res3.data);
                                        const newworkflowid = res3.data.id

                                        // window.location.replace("https://editor.workload.co/?u="+newid+"&t="+res2.data.id+"&id="+id+"&c="+newtoken)
                                        var mapdata = {
                                            method: 'post',
                                            url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap',
                                            headers: {
                                                'Content-Type': 'application/json',

                                            },
                                            data: {
                                                scaffoldId: newworkflowid,
                                                djangoId: workflowID,
                                                tenant: newtenant
                                            }
                                        }

                                        axios(mapdata)
                                            .then(function (res4) {
                                                //console.log('djangomap data ',res4.data);
                                                let data =  {
                                                    email: workemail,
                                                 }
                                        
                                                let config = {
                                                method: 'post',
                                                url: 'https://editor.workload.co:5000/addnewcontact',
                                                headers: {  
                                                    'Content-Type': 'application/json'
                                                },
                                                data : data
                                                };

                                                axios(config)
                                                .then((response) => {
                                             //   console.log(JSON.stringify(response.data));
                                                window.history.pushState("","","https://editor.workload.co/?u=" + newid + "&t=" + newtenant + "&id=" + newworkflowid + "&c=" + newtoken)
                                                handleModalClose()
                                                setUser(newid)
                                                setTenant(newtenant)
                                                const verify_code = ("" + Math.random()).substring(2, 6)
                                                localStorage.setItem('vc',verify_code)
                                                localStorage.setItem('email',workemail)
                                                

                                                setModalOpenCodeVerify(true)
                                                }).then((response) => {
                                                    const data_code = JSON.stringify(
                                                        {
                                                            email: workemail,
                                                            code: localStorage.getItem('vc')
                                                         }
                                                    )
                                                    let config_send = {
                                                        method: 'post',
                                                        url: 'https://editor.workload.co:5000/sendcode',
                                                        headers: { 
                                                            
                                                            'Content-Type': 'application/json'
                                                        },
                                                        data : data_code
                                                        };
                                                
                                                        axios(config_send)
                                                        .then((response) => {
                                                       console.log(JSON.stringify(response.data));
                                                })
                                                })
                                                .catch((error) => {
                                                console.log(error);
                                                });
                                               
                                            })
                                    })
                            })
                    })
            })
            .catch(function (error) {
                console.log('there was an error', error.response.data)
                setSignupError(error.response.data)
                setSubmitted(false)
                console.log(error);
            });
    }
   
    const [workemail, setWorkemail] = useState();
    const [validemail, setValidemail] = useState(true)
    
    const handleEmailChange = (e) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if( e !== undefined ?? e !== null ?? e.match(mailformat))
            {
              //.log('matches email address format')
            setValidemail(true);
            handleModalSignup();

            }
            else
            {
             //   console.log('does not match email format :(')
            setValidemail(false)
            }
        }
        const [validcode, setValidcode] = useState()
    const handleEvaluateCodeVerify = () => {
        var code = localStorage.getItem('vc')
        var basic1 =  document.getElementById('outlined-basic1').value
        var basic2 =  document.getElementById('outlined-basic2').value
        var basic3 =  document.getElementById('outlined-basic3').value
        var basic4 =  document.getElementById('outlined-basic4').value
        var input_code = basic1 + basic2 + basic3 + basic4
      
        if (code === input_code) {
            
            handleModalCloseCodeVerify()
        }
        else  {
           
            document.getElementById('outlined-basic1').value = ''
            document.getElementById('outlined-basic2').value = ''
            document.getElementById('outlined-basic3').value = ''
            document.getElementById('outlined-basic4').value = ''
            document.getElementById('outlined-basic1').focus()

        }
    }
    const handleKeyUp1 = () => {
         document.getElementById('outlined-basic2').focus()
    }
    const handleKeyUp2 = () => {
        document.getElementById('outlined-basic3').focus()
    }
    const handleKeyUp3 = () => {
        document.getElementById('outlined-basic4').focus()
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
    const handleKeyUp4 = async () => {
        var code = localStorage.getItem('vc')
        var basic1 =  document.getElementById('outlined-basic1').value
        var basic2 =  document.getElementById('outlined-basic2').value
        var basic3 =  document.getElementById('outlined-basic3').value
        var basic4 =  document.getElementById('outlined-basic4').value
        var input_code = basic1 + basic2 + basic3 + basic4
        if (input_code !== code) {
            setValidcode(false)
        }
        await delay(1500)
        setValidcode(true)
       handleEvaluateCodeVerify()
    }
   
    const handlePaste = async (e) => {
        e.preventDefault();
        var data = e.clipboardData.getData('Text')
        var data_arr = Array.from(data)
        document.getElementById('outlined-basic1').value = ''
        document.getElementById('outlined-basic1').value = data_arr[0]
        document.getElementById('outlined-basic2').value = data_arr[1]
        document.getElementById('outlined-basic3').value = data_arr[2]
        document.getElementById('outlined-basic4').value = data_arr[3]
        document.getElementById('outlined-basic4').focus()
    }
   
    const [submitted, setSubmitted] = useState(false)
    const classes = useStyles();
    const body = (
        <div style={modalStyle} className={breakpoint !== 'large' ? classes.modalSmall : classes.modal}>
            <img style={{display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: breakpoint !== 'large' ? '20px 0px 20px 0px' : '40px 10px 5px 10px', margin: 'auto' }} height={breakpoint !== 'large' ? '35px' : '55px'} src="https://cdn.workload.co/wp-content/uploads/cropped-new-workload-logo-high-res-900-×-193-px.png" />
            <br />
            <Typography variant="h5" style={{textAlign: 'center', margin: 'auto'}}  id="-modal-title">Create a free account</Typography>
            <Typography style={{ fontSize: '20px',fontWeight: 300, color: '#303030', textAlign: 'center', padding: breakpoint !== 'large' ? '5px 0px 20px 0px' : '5px 50px 20px 50px' }} id="simple-modal-title">You're two clicks away from <mark style={{ background: 'linear-gradient(to top, gold 50%, transparent 50%' }}>automating boring work.</mark></Typography>
            
            
            <TextField error={validemail === true ? 0 : 1} helperText={validemail === true ? "Enter a valid email address" : "Please enter valid email"} fullWidth  id="workemail" variant="outlined" label="Work Email" onChange={(e) => setWorkemail(e.target.value)} /><br /><br />
            <TextField error={pwvalid ? 0 : 1} helperText={pwvalid ? "Enter a password" : "Password can't be blank"}fullWidth id="password" type="password" variant="outlined" label="Password" placeholder="Enter your password" InputLabelProps={{shrink: true}} onChange={(e) => setPw(e.target.value)} onBlur={handlePwValid} /><br /><br />
           {signuperror ? <Alert style={{marginBottom:'10px'}} variant="filled" severity="error">
           {signuperror} - <a  href="https://automate.workload.co/auth/signin"><Typography style={{color: 'white', textDecoration: 'underline'}}>Try signing in.</Typography></a>
         </Alert> : null }
            <Button disabled={submitted ?? pwvalid === false ?? validemail === false ? 1 : 0} style={{textTransform: 'none', boxShadow: '2px 2px 5px', display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '5px', height: '50px', width: '200px', backgroundColor: '#0069ea', margin: 'auto' }} onClick={() => handleEmailChange(workemail)}>{ submitted === true ? <CircularProgress size={30} color="secondary" /> : <div><LockRoundedIcon style={{ marginLeft: '5px', color: 'white', float: 'left', display: 'block' }} /><Typography style={{ fontSize: '18px', fontWeight: 600,alignText: 'center', fontWeight: 'bold', color: 'white', float: 'left', display: 'block', marginLeft: '10px' }}>Try it free</Typography> </div> }  </Button> 
            <Typography style={{ fontSize: '14px', color: 'black', textAlign: 'center', padding: '5px 15px 15px 15px' }} id="simple-modal-subtitle">No credit card required</Typography>
            <Typography style={{fontSize: '14px', color: 'gray', textAlign: 'center', padding: '15px 15px 0px 15px' }} id="simple-modal-member">Already a member? <a style={{ color: 'black', textDecoration: 'underline'}} href="https://automate.workload.co/auth/signin">Sign in</a></Typography>
            <Typography style={{fontSize: '14px', color: 'gray', textAlign: 'center', padding: breakpoint !== 'large' ? '0px 15px 20px 15px' : '0px 15px 20px 15px' }} id="simple-modal-member"><a style={{ color: 'black', textDecoration: 'underline'}} href="https://automate.workload.co/auth/forgot-password">Forgot your password?</a></Typography>
            
            <Typography style={{float: 'left', display: 'block', textAlign: 'center', fontSize: '12px', color: 'gray', padding: '10px 30px 10px 30px' }} id="simple-modal-subtitle">By continuing, you are setting up a Workload account and agree to our <a style={{ color: '#0069ea' }} href="https://workload.co/terms" target="_blank">Terms</a> and <a style={{ color: '#0069ea' }} href="https://workload.co/privacy" target="_blank">Privacy</a> policies.</Typography>
            
        </div>
    );

    const body_tour = (
        <div style={modalStyle} className={classes.modaltour}>
            <Typography style={{ fontSize: '26px',fontWeight: 500, color: '#303030', textAlign: 'center', padding: '50px 15% 10px 15%' }} id="simple-modal-title">👋 <strong>Welcome!</strong><br/>Behind this popup is the power to automate all your boring work.</Typography>
            <Typography style={{ fontSize: '20px',fontWeight: 400, color: '#303030', textAlign: 'center', padding: '10px 0px 10px 0px' }} id="simple-modal-title">We'd love to show you how - <mark style={{ background: 'linear-gradient(to top, gold 50%, transparent 50%' }}>it'll be fast, promise.</mark></Typography>
            <img style={{display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '10px 10px 15px 10px', margin: 'auto', cursor: 'pointer' }} height='20%' src="https://cdn.workload.co/wp-content/uploads/welcometour.gif" onClick={()=> {mixpanel.track('Tour Start', {'user': user}); handleModalCloseTour(); setTourStart(true)}}/>
            <Button style={{boxShadow: '2px 2px 5px #303030', display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '10px', height: '50px', width: '200px', backgroundColor: '#0069ea', borderRadius: '4px', border: 'none', cursor: 'pointer', margin: 'auto' }} onClick={()=> {mixpanel.track('Tour Start', {'user': user}); handleModalCloseTour(); setTourStart(true)}}><div><Typography style={{ alignText: 'center', fontWeight: 'bold', color: 'white', float: 'left', display: 'block' }}>START A QUICK TOUR</Typography></div></Button> <br/>
            <div style={{width:'150px', margin: 'auto', justifyContent: 'center'}} onClick={()=> {handleModalCloseTour(); setTourStart(false); localStorage.setItem('tourReady',false)}}><Typography style={{ fontSize: '14px', color: 'black', textAlign: 'center', padding: '25px 15px 15px 15px', cursor: 'pointer' }} id="simple-modal-subtitle">Skip the tour</Typography></div>
            <Alert severity="info" style={{ margin: 'auto', justifyContent: 'center' }} variant="filled"><Typography style={{fontSize: '14px'}} id="simple-modal-title">Complete your first milestone by automating an example process.</Typography></Alert>

            
        </div>
    );

    const body_codeverify = (
        <div style={modalStyle} className={classes.modalverify} >
            <Typography style={{ fontSize: '26px',fontWeight: 500, color: '#303030', textAlign: 'center', padding: '50px 15% 10px 15%' }} id="simple-modal-title">Please check your email.</Typography>
            <Typography style={{ fontSize: '20px',fontWeight: 400, color: '#303030', textAlign: 'center', padding: '10px 0px 10px 0px' }} id="simple-modal-title">We've sent a verification code to: <mark style={{ background: 'linear-gradient(to top, gold 50%, transparent 50%' }}>{workemail}</mark></Typography>
            <Typography style={{ fontSize: '16px',fontWeight: 400, color: 'grey', textAlign: 'center', padding: '50px 15% 10px 15%' }} id="simple-modal-title">Be sure to mark it as NOT SPAM if the email wound up in your spam folder.</Typography>

            <div id='container' style={{width: '100%', display: 'flex', marginBottom: '25px'}}>
            <div id='thing1' style={{width: '20%', padding: '2%'}}><TextField style={{border: validcode === false ? '2px solid red' : null, borderRadius: validcode === false ? '4px' : null}} autoFocus InputProps={{max: 1, style: { fontSize: 40, paddingLeft: 20 } }} id="outlined-basic1"  variant="outlined" onKeyUp={(e) => { handleKeyUp1(e.target.value); }} onPaste={ (e) => handlePaste(e) } /></div>
            <div id='thing2' style={{width: '20%', padding: '2%'}}><TextField style={{border: validcode === false ? '2px solid red' : null, borderRadius: validcode === false ? '4px' : null}} InputProps={{max: 1, style: { fontSize: 40, paddingLeft: 20 } }} id="outlined-basic2"  variant="outlined" onKeyUp={(e) => { handleKeyUp2(e.target.value); }} /></div>
            <div id='thing3' style={{width: '20%', padding: '2%'}}><TextField style={{border: validcode === false ? '2px solid red' : null, borderRadius: validcode === false ? '4px' : null}} InputProps={{max: 1, style: { fontSize: 40, paddingLeft: 20 } }} id="outlined-basic3"  variant="outlined" onKeyUp={(e) => { handleKeyUp3(e.target.value);}}  /></div>
            <div id='thing4' style={{width: '20%', padding: '2%'}}><TextField style={{border: validcode === false ? '2px solid red' : null, borderRadius: validcode === false ? '4px' : null}} InputProps={{max: 1, style: { fontSize: 40, paddingLeft: 20 } }} id="outlined-basic4"  variant="outlined" onKeyUp={(e) => { handleKeyUp4(e.target.value); }}  /></div>
            </div>
            <Button style={{textTransform: 'none', boxShadow: '2px 2px 5px #303030', display: 'flex', justfiyContent: 'center', alignItems: 'center', padding: '10px', height: '50px', width: '200px', backgroundColor: '#0069ea', borderRadius: '4px', border: 'none', cursor: 'pointer', margin: 'auto' }} onClick={()=> {mixpanel.track('Verify Email', {'user': user}); handleModalCloseTour(); }}><div><Typography style={{ alignText: 'center', fontWeight: 'bold', color: 'white', float: 'left', display: 'block' }}>Verify</Typography></div></Button> <br/>
            
            <Alert severity="warning" style={{ margin: 'auto', justifyContent: 'center' }} variant="filled"><Typography style={{fontSize: '14px'}} id="simple-modal-title">Didn't get a code? Click to resend.</Typography></Alert>

            
        </div>
    );

    const body_desktop_reminder = (
       
            <div style={modalStyle} className={breakpoint !== 'large' ? classes.modalSmall : classes.modal}>
                <img style={{ marginBottom: breakpoint !== 'large' ? '20px' : '50px' }} height='25px' src="https://cdn.workload.co/wp-content/uploads/cropped-new-logo-only-900x193-1.png" />
                
                <br />
                <Typography style={{width: '100%', fontSize: breakpoint !== 'large' ? '30px' : null}} variant="h4" id="simple-modal-title">Switch to <mark style={{ background: 'linear-gradient(to top, gold 50%, transparent 50%' }}>Desktop</mark></Typography>
                <br/>
                <Typography style={{ fontSize: breakpoint !== 'large' ? '14px' :'18px' }} id="simple-modal-subtitle">It looks like you're on mobile. Our software really does its magic on desktop. Hit <strong>Remind Me</strong> to get an email with your unique sign in link!</Typography>
                <br />
                
                <TextField error={validworkemail === true ? 0 : 1} helperText={validworkemail === true ? "Enter a valid email address" : "INVALID EMAIL"} style={{ width: '100%' }} id="workemail" variant="outlined" label="Work Email" onChange={(e) => setDeskWorkemail(e.target.value)} /><br /><br />
                
                <Button variant="text" color="default" style={{ float: 'left', textTransform: 'none', fontSize: breakpoint === 'small' ? '14px' : null }} onClick={() => setDesktopReminderModal(false)}>Dismiss</Button>
                <Button disabled={deskworkemail ? 0 : 1} style={{ width: breakpoint === 'small' ? '140px' : '175px', textTransform: 'none', float: 'right', display: 'block'}} variant='contained' color='primary' onClick={() => handleDeskWorkemailChange(deskworkemail)}>{ desktopreminderloading === true ? <CircularProgress size={30} color="secondary" /> : 
                <div>
               
                <Typography style={{float: 'left', fontWeight: 'bold', color: 'white', marginTop: '2px',fontSize: breakpoint === 'small' ? '14px' : null }}>Remind Me</Typography> 
                <ScheduleRoundedIcon style={{ marginLeft: '5px', color: 'white', float: 'right', display: 'block' }} /></div> }  
                </Button> 
                
                <br />
            </div>
    );

    const sendLoggedOutToAuthorizer = (isLoggedOut) => { // the callback. Use a better name
      //  console.log('user is logged out? ', isLoggedOut);
        handleModalOpen();
    };

    const [counter, setCounter] = useState(7);
    useEffect(() => {
        const timer =
          counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
          if (counter === 0 && !user && modalwidth < 430){
           // setDesktopReminderModal(true);
          }
        return () => clearInterval(timer);
      }, [counter]);


    const steps =  [
        {
          target: '#trigger-empty',
          title: (<div style={{fontSize: '18px', fontWeight: 600}}>This a trigger step</div>),
          content: (<div>Click inside the <strong>App dropdown</strong> to start automating a business process.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>A trigger is the first step in a workflow. We'll listen for changes to data in an app and then perform actions later on as a result of those changes.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
          disableBeacon: true,
          hideCloseButton: false,
          disableOverlayClose: true,
          disableScrolling: true,
          showSkipButton: true,
          hideFooter: true,
          placement: 'right',
          spotlightClicks: true,
          styles: {
              options: {
                primaryColor: '#0069ea',
                textColor: 'black',
                zIndex: 99999,
              },
            }
        },
        {
          target: '#trigger-combo-box-demo',
          title: (<div style={{fontSize: '18px', fontWeight: 600}}>Choose trigger app</div>),
          content: (<div>For this example, type <strong>Workload Demo</strong> into the search box.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This is the app where we will listen for events that will start a business process.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
          placement: 'right',
          disableBeacon: true,
          disableScrolling: true,
          hideCloseButton: false,
          disableOverlayClose: false,
          showSkipButton: true,
          hideFooter: true,
          disableOverlay: true,
          styles: {
            options: {
                primaryColor: '#0069ea',
                textColor: 'black',
                zIndex: 99999,
              },
            }
        },
        {
            target: '#trigger-choose-event',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Choose trigger event</div>),
            content: (<div>Our process will start <i>when a new user is created in Workload Demo</i>.<br/><br/>Select <strong>New User Created</strong> from the dropdown list and click <strong>Continue</strong>.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>An event is something that happens inside of an app that kicks off a workflow.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            hideFooter: true,
            hideCloseButton: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#trigger-save',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}></div>),
            content: (<div>If you see sample data, click on <strong>Save & Close</strong> at the bottom.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This is the data we can reference throughout the rest of the workflow.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right-end',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: true,
            offset: 0,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black'
                
                },
              }
          },
          {
            target: '#root',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}><br/>🙌🏼 You're on a roll!<br/><img src="https://cdn.workload.co/wp-content/uploads/workloadtourhalf.gif" height='200px' /></div>),
            content: (<div><strong>Now let's create an action.</strong><br/><br/><div style={{fontSize: '12px', color: 'gray'}}>An automated process must consist of at least two elements, a trigger and an action.</div></div>),
            placement: 'center',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: false,
            showSkipButton: true,
            showProgress: false,
            hideBackButton: true,
            disableOverlay: false,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#edit-button-action',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>This is an action step</div>),
            content: (<div>Click inside the <strong>action step</strong> to finish building your business process.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>Actions come after the trigger step in a workflow. Actions are used to create, update, and search data in other software tools automatically!</div></div>),
            disableBeacon: true,
            hideCloseButton: false,
            disableOverlayClose: true,
            disableScrolling: true,
            showSkipButton: true,
            hideFooter: true,
            placement: 'right',
            spotlightClicks: true,
            styles: {
                options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#action-combo-box-demo',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Choose action app</div>),
            content: (<div>Like before, type <strong>Workload Demo</strong> into the search box.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This is the app where we will create, update, or search data to automate business processes.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            disableScrolling: true,
            hideCloseButton: false,
            disableOverlayClose: false,
            showSkipButton: true,
            hideFooter: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#choose-action',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Choose action</div>),
            content: (<div>We want to <i>create a new Todo item</i> when a <i>new contact is created</i> in our worklfow.<br/><br/>Select <strong>Create Todo</strong> from the dropdown list.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>An action is a step in the workflow that creates, updates, or searches data inside of an app.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            disableScrolling: true,
            hideFooter: true,
            offset: 0,
            hideCloseButton: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#action-setup',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Set up action<br/><img src="https://cdn.workload.co/wp-content/uploads/mapfieldsdemo.gif" width='100%' /></div>),
            content: (<div>Map the <strong>contact name</strong> data from the trigger step by clicking into the <strong>Title</strong> field.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This is how you reference data when buildling a worklfow.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#actions-test',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Test action</div>),
            content: (<div>Send sample data to the app by clicking on <strong>Test Action</strong>.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>Sending sample data ensures that the workflow will execute properly when it is turned on.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#actions-save',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}>Save & Close action</div>),
            content: (<div>Click <strong>Save & Close</strong> to commit this step.<br/><br/><div style={{fontSize: '12px', color: 'gray'}}>This step ensures the data references are saved properly during run-time.</div><MobileStepper style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex',maxWidth: 200,flexGrow: 1, margin: 'auto'}} variant="dots" steps={12} position="bottom" activeStep={stepIndex} /></div>),
            placement: 'right',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: true,
            disableOverlay: true,
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
          {
            target: '#root',
            title: (<div style={{fontSize: '18px', fontWeight: 600}}><br/>BOOM SHAKALAKA!<br/><img src="https://cdn.workload.co/wp-content/uploads/welcometourcomplete.gif" height='200px' /></div>),
            content: (<div><strong>You just created your first automated process using Workload.</strong><br/><br/><div style={{fontSize: '12px', color: 'gray'}}>An automated process must consist of at least two elements, a trigger and an action.</div></div>),
            placement: 'center',
            disableBeacon: true,
            hideCloseButton: true,
            hideFooter: false,
            showSkipButton: true,
            showProgress: false,
            hideBackButton: true,
            disableOverlay: false,
            locale: {last: 'Finish'},
            styles: {
              options: {
                  primaryColor: '#0069ea',
                  textColor: 'black',
                  zIndex: 99999,
                },
              }
          },
      ];
const [renderready, setRenderReady] = useState(false)
      const handleJoyrideCallback = (props) => {
        const { action, index, status, type, lifecycle } = props;
       // console.log('joyride props are: ', props)
      // console.log('joyride stepIndex = ', stepIndex)

        if (action === 'next' && index === 4){
            mixpanel.track('Tour: Step 5 Completed', {
                'user': user
            });
            setStepIndex(5)
        }

        if (action === 'next' && index === 11){
            mixpanel.track('Tour: Step 11 Completed', {
                'user': user
            });
            setTourStart(false)
        }

        if (action === 'update'){
            setRenderReady(true)
        }
        if (action === 'next'){
            setRenderReady(false)
        }

        if (action === 'close') {
            setTourStart(false)
        }
        
        

      }

       useEffect(() => {
        if (run === true && renderready === true && stepIndex === 1 && selectedElement && selectedElement.data.app === 'workload-demo'){
            mixpanel.track('Tour: Step 1 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 2 && selectedElement && selectedElement.data.action === 'newUser'){
            mixpanel.track('Tour: Step 2 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 3 && selectedElement === undefined){
            mixpanel.track('Tour: Step 3 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 4 && selectedElement && selectedElement.data.testData){
            mixpanel.track('Tour: Step 4 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 6 && selectedElement && selectedElement.data.type === 'workload-demo'){
            mixpanel.track('Tour: Step 6 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 7 && selectedElement && selectedElement.data.action === 'createTodo'){
            mixpanel.track('Tour: Step 7 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 8 && selectedElement && selectedElement.data.fields[0].value && selectedElement.data.fields[0].value.title){
            mixpanel.track('Tour: Step 8 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 9 && selectedElement && selectedElement.data.fields[0].value && selectedElement.data.testData){
            mixpanel.track('Tour: Step 9 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }

        if (run === true && renderready === true && stepIndex === 10 && selectedElement === undefined){
            mixpanel.track('Tour: Step 10 Completed', {
                'user': user
            });
            setStepIndex(stepIndex + 1)
        }


      }, [selectedElement]) 
      
      
      const colors = [
        'primary',
        'secondary',
      ];
      
      const [color, setColor] = useState(0);
    
      useEffect(() => {
        if (workflowloading === true) {
          const interval = setInterval(() => {
            setColor((v) => (v === 2 ? 0 : v + 1));
          }, 1500);
        }
      }, []); 

      

    try {
        return (
            <>
            
                {workflow && elements && accessDenied === false ?
                    
                    <AppBarHeader selectedElement={selectedElement} breakpoint={breakpoint} isactive={active} sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer} style={{ width: '100vw' }} /> :
                   <> <AppBarHeaderDummy style={{ width: '100vw' }} />
                   <div style={{width: '100%', display: 'flex'}}>
                   <div style={{justifyContent: 'center', margin: '0 auto', width: '75%'}}>
                   <div style={{ marginTop: '50px'}}>
                   <Typography variant="h3">No access to workflow</Typography>
                   </div>
                   <div style={{marginTop: '24px'}}><Typography style={{fontSize: '20px'}} variant="body">You either don't have permission to see this Workflow or the Workflow does not exist.
                    Please try reloading the page or switching to a different account.</Typography></div>
                    <div style={{marginTop: '24px', justifyContent: 'center'}}><Typography style={{fontSize: '16px'}} variant="body">Need help? Contact support .</Typography></div>
                   </div>
                   </div>  </>}
                {workflowloading === false && workflow && elements.length > 0 && accessDenied === false ?
                    <div onKeyDown={handleKeyDownSave} tabIndex='0' style={{ width: '100vw', height: '100vh' }}>      
                                   
                        <ReactFlowProvider>
                            <div>
                                <ReactFlow
                                    elements={elements}
                                    style={flowStyle}
                                    connectionLineComponent={ConnectionLine}
                                    onLoad={onLoad}
                                    onElementsRemove={onElementsRemove}
                                    onConnect={onConnect}
                                    onConnectStart={handleonConnectStart}
                                    onConnectStop={handleLineDrop}
                                    selectNodesOnDrag={false}
                                    onElementClick={handleElementClick}
                                    deleteKeyCode={248}
                                    onPaneClick={handlePaneClick}
                                    nodeTypes={nodeTypes}
                                    edgeTypes={edgeTypes}
                                    onNodeDragStop={handleNodeMove}
                                    snapToGrid={true}
                                    snapGrid={[20, 20]}
                                    //maxZoom={1.5}
                                    //defaultZoom={defaultZoom}
                                    
                                    //defaultPosition={breakpointPosition}
                                    paneMoveable={selectedElement ? false: true}
                                    zoomOnScroll={false}
                                    zoomOnPinch={false}
                                    zoomOnDoubleClick={false}
                                    showZoom={true}
                                    showFitView={true}
                                    
                                   // showInteractive={false}
                                    panOnScroll={true}
                                //    sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer}
                                >
                                <Joyride
                                 callback={handleJoyrideCallback}
                                 continuous
                                 disableScrollParentFix={true}
                                 run={run}
                                 disableScrolling={false}
                                 showProgress
                                 showSkipButton
                                 stepIndex={stepIndex}
                                 steps={steps}
                             />
                                   {notify && notify.status ? <Snackbar open={openalert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                                        <Alert onClose={handleCloseAlert} severity={notify.status.status} variant="filled">
                                           <Typography style={{fontSize: '16px'}}>{notify.status.message}</Typography>
                                        </Alert>
                                    </Snackbar> : null }
                                    
                                    <div style={{ position: 'relative', zIndex: 5 }}>
                                    
                                   {createTrigger === true ?  <CreateTriggerPanel key={'create-trigger-panel'} breakpoint={breakpoint} user={user} tenant={tenant} app={createTriggerApp} onPaneClick={handlePaneClick}   /> : null}
                                   {createSupportTicket === true ?  <CreateSupportTicketPanel key={'create-support-ticket-panel'} breakpoint={breakpoint} user={user} tenant={tenant} app={createSupportTicketApp} onPaneClick={handlePaneClick}   /> : null}
                                        {
                                            selectedElement ?
                                            
                                                selectedElement.type == 'action' ?

                                                    <ActionPanel breakpoint={breakpoint} user={user} tenant={tenant} selectedNode={selectedElement} onPaneClick={handlePaneClick} sendNode={updateNode} deleteNode={onElementsRemove} workflowID={workflow.id} sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer}/>
                                                    : selectedElement.type == 'trigger' ?
                                                      <TriggerPanel className='trigger-card' breakpoint={breakpoint} user={user} tenant={tenant} selectedNode={selectedElement} onPaneClick={handlePaneClick} createCustomTrigger={handleCreateTrigger} createSupportTicket={handleCreateSupportTicket} sendNode={updateNode} sendLoggedOutToAuthorizer={sendLoggedOutToAuthorizer} /> 
                                                        : selectedElement.type == 'filter' ?
                                                            <FilterPanel key={'filter-panel'} breakpoint={breakpoint} user={user} tenant={tenant} selectedEdge={selectedElement} onPaneClick={handlePaneClick} sendEdge={updateNode}  /> :
                                                             

                                                            null
                                                : null
                                                
                                        }

                                    </div>
                       
                                    <Modal
                                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                       
                                        open={modalopen}
                                        //onClose={handleModalClose}
                                        aria-labelledby="simple-modal-title"
                                       
                                    >
                                        {body}
                                    </Modal>
                                    <Modal
                                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                        open={desktopReminderModal}
                                        onClose={handleDesktopModalClose}
                                        aria-labelledby="simple-modal-title"
                                       
                                    >
                                        {body_desktop_reminder}
                                    </Modal>
                                    <Modal
                                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                        open={modalopentour}
                                        onClose={handleModalCloseTour}
                                        aria-labelledby="simple-modal-title"
                                       
                                    >
                                        {body_tour}
                                    </Modal>
                                    <Modal
                                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                                        open={modalopencodeverify}
                                      // onClose={handleModalCloseCodeVerify}
                                        aria-labelledby="simple-modal-title"
                                       
                                    >
                                        {body_codeverify}
                                    </Modal>{ elements?.length > 8 ? 
                                    <MiniMap
                                    style={{marginBottom: '65px',position: 'absolute',left: 50}}
                                        nodeStrokeColor={(n) => {
                                        if (n.style?.background) return n.style.background;
                                        if (n.type === 'trigger') return '#ff0072';
                                        if (n.type === 'action') return '#0069ea';
                                        

                                        return '#eee';
                                        }}
                                        nodeColor={(n) => {
                                        if (selectedElement?.id === n.id) return '#000000'
                                        if (n.style?.background) return n.style.background;
                                        if (n.type === 'trigger') return '#ff0072';
                                        if (n.type === 'action') return '#0069ea';
                                        
                                        return '#fff';
                                        }}
                                        nodeBorderRadius={2}
                                    /> : null }
                                    <Controls style={{marginBottom: '65px'}} />
                                    <Background
                                        variant="dots"
                                        gap={16}
                                        size={1}
                                        color={"#d7d5d2"}
                                        />
                                </ReactFlow>
                            </div>
                        </ReactFlowProvider>
                    </div> :
                    workflowloading === true ? <div style={{margin: 'auto', width: '100%', display: 'flex', justifyContent: 'center', top: '50%'}} ><CircularProgress style={{ left: '50%', top: '50%', position: 'absolute' }} color="secondary" size={50} /></div> : null }
            </>
        )
    }
    catch (e) {

        var config = {
            method: 'post',
            url: 'https://api.airtable.com/v0/appb76LyMibAJXsfE/FrontendErrors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer key3FrNljA0k5KIGo'
            },
            data: {
                'records': [
                    {
                        'fields': {
                            'Error source': 'flowApp',
                            'Error': e.toString()
                        }
                    }
                ]
            }
        }

        axios(config)

        return (
            <div>
                Something broke, please refresh
            </div>
        )
    }
};
