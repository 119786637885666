import React, { useState, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";

import axios from 'axios';
import { Card, Grid, CircularProgress } from "@material-ui/core";
import Canvas from '../flowApp';
import { AppBarHeaderDummy } from "../components/AppBarDummy";
import { fetchAllApps } from "../features/allApps/allAppsSlice";
import { useDispatch } from "react-redux";

const Editor = () => {



  const colors = [
    'primary',
    'secondary',
  ];
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(fetchAllApps(1))
  }, [])

  const [color, setColor] = useState(0);

  useEffect(() => {
    if (loading === true) {
      const interval = setInterval(() => {
        setColor((v) => (v === 2 ? 0 : v + 1));
      }, 1500);
    }
  }, []);

  const [djangoId, setDjangoId] = useState(null);
  const [loading, setLoading] = useState('initializing');

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const id = query.get("id")
  const user = query.get("u");
  const tenant = query.get("t")

  const getDjangoWorkflow = () => {
    var config = {
      method: 'get',
      url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/scaffolddjangomap?scaffoldId=' + id,
      headers: {
        'Content-Type': 'application/json'
      }

    };
    axios(config)
      .then(function (response) {
        var datalength = response.data.length;
       // console.log('length is ', datalength)
        if (datalength === 0) {
          //if the scaffoldmapper doesn't return anything, create a fresh workflow
        //  console.log('saving new django, then posting to scaffolddjangomapper');

          setLoading('loaded');
        }
        else {
          //get the frontendWorkflow using id from the above function and return the workflow
        //  console.log('get success to docdb for scaffolddjangomap', response.data);
          var idfromdjango = response.data[0].djangoId;
       //   console.log('djangoid from mapper is ', idfromdjango);
          setDjangoId(idfromdjango)
          setLoading('loaded');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (loading != 'loaded') {
      getDjangoWorkflow()
     // console.log('getting djangoworkflow')
    } else {

    }
  }, [loading])


  return (
    <div style={{ paddingTop: 0, paddingLeft: 0 }}>
      <Grid >
        <Grid >
          <Card style={{ spacing: 2, paddingTop: 0 }}>
            {loading != 'loaded' ? <Fragment>
              <div style={{ width: '100vw', height: '100vh' }}>
                <AppBarHeaderDummy />
                <CircularProgress style={{ left: '50%', top: '50%', position: 'absolute' }} id='circularprogresscolor' color={colors[color]} size={50} />
              </div>
            </Fragment>
              :
              <div id='canvas-editorjsxpage' style={{ width: '100vw', height: '100vh' }}>
              
                <Canvas workflowID={djangoId} />
              </div>}
          </Card>
        </Grid>
      </Grid>
    </div>

  );
};

export default Editor;