import React, { useEffect, useState, Fragment } from 'react';
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import styles from '../Panel.module.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { Paper, Chip, Accordion, CircularProgress, AccordionDetails, Typography, Stepper, Step, StepLabel, StepContent, StepButton, Button, InputLabel, MenuItem, FormControl, Select, TextField, ListItemIcon, Avatar, ListItemText, Grid, AccordionSummary } from '@material-ui/core'
import { isTemplateSpan, updateFunctionDeclaration } from 'typescript';
import Authorizer from '../Authorizer'
import Inspector from 'react-json-inspector';
import '../helpers/json-inspector.css';
import '../helpers/workload-tags.css'; //add styling for the tags in input fields
import '../helpers/workload-mode-single-line-style.css';// add .cm-custom-keyword class here
import 'codemirror/addon/search/searchcursor';
import { v4 as uuid } from 'uuid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { alertName } from '../../redux/ducks/notification';
import { ptree } from '../../redux/ducks/pTree';
import { nodeid } from '../../redux/ducks/nodeID';
import { edgetarget } from '../../redux/ducks/edgetarget';
import Form from "@rjsf/material-ui"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {FieldsRender} from '../helpers/panel-functions'

import {
    nodeAdded, edgeAdded, elementUpdated, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected, testNode,
    selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplate, selectWorkflowActive, selectCurrentElement
} from '../../features/workflow/workflowSlice'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    removeappbutton: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(60),
    },
    triggersContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(1),
    },
    selectInput: {
        '*': {
            display: 'flex'
        }
    },
    chiproot: {
        display: 'flex',
        left: 5,
        marginLeft: '20px',
        width: '99%',
        float: 'left',
        '& > *': {
            margin: '-10px',

        },
        "& > *:hover": {
            border: '2px solid',
            borderColor: "#0069ea"
        }
    },
    accordionroot: {
        width: '90px',
    },
    accordionheading: {
        fontWeight: 700,
    },
    dropdownroot: {
        position: 'relative',
    },
    small: {
        width: '22px',
        height: '22px',
        marginRight: '4px'
    },
    dropdown: {
        position: 'absolute',
        width: '500px',
        right: 0,
        left: 10,
        zIndex: 5,

        // borderTop: '1px solid',
        // borderLeft: '1px solid',
        boxShadow: "1px 0px 0px lightgrey",
        borderColor: 'lightgrey',
        borderRadius: '5px',
        padding: '4px',
        backgroundColor: 'white',
        opacity: '1',
        overflowX: "hidden",
        maxHeight: '350px',
        overflowY: "scroll",

    },
    codemirror: {
        marginTop: '7px',
        border: '1px solid',
        borderColor: 'lightgrey',
        borderRadius: '4px',
        paddingTop: '4px',
        paddingRight: '4px',
        paddingBottom: '4px',
        justifyContent: 'center',
        width: 'inherit',
        height: '48px',
        display: 'relative',
        //position: 'absolute'
        '&:hover': {
            borderColor: 'black',
            border: '1px solid'
        },
        '&:focus-within': {
            borderColor: '#0069ea',
            border: '2px solid'
        }
    },
}));

const muiTheme = createMuiTheme({
    palette: {
        primary: { main: '#0069ea' },
        secondary: { main: '#ff5555' }
    },
    props: {
        style: { borderColor: '#0069ea' },
        MuiTextField: {
            variant: 'outlined'

        }
    }
});


function FilterPanel({ selectedEdge, deleteEdge, sendEdge, workflowID, parentTree, onPaneClick }) {
    const [activeStep, setStep] = useState(0);
    const edge = useSelector(selectElementById(selectedEdge.id))
    const [name, setName] = useState(edge.data.name);
    const [type, setType] = useState(edge.data.type)
    const [label, setLabel] = useState(edge.data.label);
    const [icon, setIcon] = useState(edge.data.icon);
    const [loading, setLoading] = useState(true);
    const [executions, setExecutions] = useState([]);
    const [nosave, setNoSave] = useState(0)
    const dispatch = useDispatch();
    const colors = [
        'primary',
        'secondary'
    ];
    const [fields, setFields] = useState(edge.data.fields)
    const [testData, setTestData] = useState(edge.data.testData)

    const [color, setColor] = useState(0);

    // React.useEffect(() => {
    //     const interval = setInterval(() => {
    //         setColor((v) => (v === 2 ? 0 : v + 1));
    //     }, 1500);
    // }, []);

    useEffect(() => {
        let isMounted = true;
        saveEdge();
        return () => { isMounted = false }
    }, [fields, name, type, label, icon])

    // console.log(edge)
    // dispatch(edgetarget(edge.target));

    const saveEdge = () => {
      //   console.log('saving node')
      //   console.log(edge)
     /*     sendEdge(Object.assign({},edge, {
             data: {
                 name: name,
                 type: type,
                 label: label,
                 icon: icon,
                 fields: fields,
                 testData: testData
             }
         })) */
         
        dispatch(elementUpdated(selectedEdge.id,
            {
                name: name,
                type: type,
                label: label,
                icon: icon,
                fields: fields,
                testData: testData
            },
            null))
    }

    const setNodeNameAuto = (e) => {
        // edge.data.name = label + ' - ' + e;
        setName(label + ' - ' + e);
        // saveEdge(edge);
        // console.log('name is now, ', edge.data.name)
    }

    // const sendAlert = () => {
    //     dispatch(alertName({ "severity": "success", "message": edge.data.name + " saved successfully" }))
    // }

    // const resetForm = () => {
    //     setStep(activeStep - 1)
    //     setTestData(null);
    // }
    const classes = useStyles();

    console.log(classes)

    return (
        <>
            <div key={edge} className={styles.Panel} style={{paddingBottom: '60px'}}>
                <FieldsRender key={edge} fields={fields} setFields={setFields} parentTree={parentTree} nodeID={edge.id} />
   
            </div>
            
        </>
    )
}

const Widget = (props) => {
    return <span>
        <img
            className="tag-img"
            src={props.icon}>
        </img>
        <b>{props.step} - {props.label}: </b><div style={{ display: 'inline', color: 'grey' }}>{props.cvalue}</div>
    </span>;
}

export default FilterPanel

