import React, { useState, useEffect, Fragment, useRef } from 'react'
import { Paper, Chip, Accordion, CircularProgress, Divider, IconButton, ClickAwayListener, Menu, Popper, Tooltip, AccordionDetails, Typography, Stepper, Step, StepLabel, StepContent, StepButton, Button, InputLabel, MenuItem, FormControl, Select, TextField, ListItemIcon, Avatar, ListItemText, Grid, AccordionSummary, InputAdornment, Modal, Radio } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Form from "@rjsf/material-ui";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import ReactDOM from "react-dom";
import { UnControlled as CodeMirror } from 'react-codemirror2';
import Inspector2 from 'react-json-inspector';
import '../helpers/json-inspector-dropdown.css';
import '../helpers/workload-mode-style.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import UnfoldMoreRoundedIcon from '@material-ui/icons/UnfoldMoreRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import 'codemirror/theme/neat.css';


//import {Decoration} from "@codemirror/state"

import {
    nodeAdded, edgeAdded, elementUpdated, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected, testNode, loadingperform,
    selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplateNodes, selectWorkflowActive, selectCurrentElement
} from '../../features/workflow/workflowSlice'

import {
    selectExecutionParentTree, selectCurrentElement as selectExecutionCurrentElement
} from '../../features/execution/executionSlice'

import { Autocomplete } from '@material-ui/lab';
import SearchRounded from '@material-ui/icons/SearchRounded';
import { setDefaultResultOrder } from 'dns';
import { createSemanticDiagnosticsBuilderProgram } from 'typescript';

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');

    if (s == '') {
        return o
    }
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    removeappbutton: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    actionsContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(1),
    },
    selectInput: {
        '*': {
            display: 'flex'
        }
    },
    chiproot: {
        display: 'flex',
        left: 5,
        marginLeft: '4px',
        width: '99%',
        float: 'left',
        '& > *': {
            margin: '-10px',

        },
        "& > *:hover": {

        }
    },
    accordionroot: {
        width: '90px',
    },
    accordionheading: {
        fontWeight: 700,
        width: '100%'
    },
    dropdownroot: {
        position: 'relative',
    },
    radio: {
        '&:hover': {
          fontWeight: 'bolder',
    
        },
      },
    small: {
        width: '22px',
        height: '22px',
        marginRight: '4px'
    },
    dropdown: {
        position: 'absolute',
        width: '95%',
        right: 0,
        left: 10,

        zIndex: 999,
        marginTop: 5,
        // borderTop: '1px solid',
        borderLeft: '1px solid',
        boxShadow: "1px 0px 0px lightgrey",
        borderColor: 'lightgrey',
        borderRadius: '5px',
        padding: '4px',
        backgroundColor: 'white',
        opacity: '1',
        overflowX: "hidden",
        maxHeight: '350px',
        overflowY: "auto",

    },
    codemirror: {
        marginTop: '7px',
   
        border: '1px solid',
        borderColor: 'lightgrey',
        borderRadius: '4px',
        paddingTop: '4px',
        paddingRight: '4px',
        paddingBottom: '4px',
       
        width: 'inherit',
        height: 'auto',
        // display: 'relative',
        overflowX: 'hidden',
        //position: 'absolute'
        '&:hover': {
            borderColor: 'black',
            border: '1px solid'
        },
        '&:focus-within': {
            borderColor: '#0069ea',
            border: '2px solid'
        }
    },
    resetContainer: {
        padding: theme.spacing(1),
    },
    modal: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const ChipContextMenu = ({ parentRef, info }) => {
    const [visible, setVisible] = useState(false)
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)

    const items = [
        {
            text: 'Remove Reference',
            onClick: info.deleteClick
        }
    ]
    useEffect(() => {
        const parent = parentRef.current;
        if (!parent) {
            return
        }

        const showMenu = (event) => {
            event.preventDefault();

            console.log('right clicking chip')
            console.log(event)

            setVisible(true)
            setX(event.clientX)
            setY(event.clientY - 20)

        }

        const closeMenu = () => {
            setVisible(false)
        }

        parent.addEventListener('contextmenu', showMenu)
        window.addEventListener('click', closeMenu)

        return function cleanup() {
            parent.removeEventListener('contextmenu', showMenu)
            window.removeEventListener('click', closeMenu)
        }
    })

    return visible ? (

        <div className='chip-context-menu' style={{ top: y, left: x, position: 'fixed', backgroundColor: 'whitesmoke', marginBottom: '200px', zIndex: 999 }}>

            {items.map(el => {
                return <MenuItem onClick={el.onClick}>{el.text}</MenuItem>
            })}

        </div>

    ) : null
}
const DynamicChip = (props) => {
    const widgetRef = useRef(null)
    
    return (
        <>
            <span style={{ fontFamily: 'Helvetica, sans-serif', fontSize: '14px', padding: '6px 2px 6px 8px', border: '1px solid #0069EA', borderRadius: '4px' }} ref={widgetRef}>
                <img
                    className="tag-img"
                    src={props.icon}>
                </img>
                <b>{props.label}</b>
            </span>
            <ChipContextMenu parentRef={widgetRef} info={props} />
        </>
    )
}

const WorkflowChip = (props) => {
    const widgetRef = useRef(null)
    //console.log('workflow chip props::: ', props)

    return (
        <>
            <span style={{ fontSize: '14px', padding: '7px 8px 2px 14px', border: '1px solid #0069ea', borderRadius: '4px' }} ref={widgetRef}>
                <img
                    className="tag-img"
                    src={props.icon}>
                </img>
                <div style={{display: 'inline', textTransform: 'uppercase' }}><b>{props.step ? props.step + "." : ""} {props.label}: </b></div><div style={{ display: 'inline', color: 'grey' }}>{props.cvalue?.length > 50 ? props.cvalue.substring(0, 50) + '...' : props.cvalue}</div>
            </span>
            <ChipContextMenu parentRef={widgetRef} info={props} />
        </>
    )
}

const ExecutionChip = (props) => {
    const widgetRef = useRef(null)

    return (
        <>
            <span style={{ fontSize: '14px', padding: '7px 8px 2px 14px', border: '1px solid #0069ea', borderRadius: '4px' }} ref={widgetRef}>
                <img
                    className="tag-img"
                    src={props.icon}>
                </img>
                <div style={{display: 'inline', textTransform: 'uppercase' }}><b>{props.label}: </b></div><div style={{ display: 'inline', color: 'grey' }}>{props.cvalue?.length > 50 ? props.cvalue.substring(0, 50) + '...' : props.cvalue}</div>
            </span>
            <ChipContextMenu parentRef={widgetRef} info={props} />
        </>
    )
}

const ExecutionWidget = (props, id) => {

    const [value, setValue] = useState(props.value);
    const [open, setOpen] = useState(false);
    const parentTree = useSelector(selectExecutionParentTree);
    const nodeId = useSelector(selectExecutionCurrentElement);
    const nodeData = useSelector(selectElementById(nodeId))
    const parents = parentTree[nodeId];

    const widgetRe = /\${(([^}][^}]?|[^}]}?)*)}/;
    const widgetRe2 = /[\s\S]+/;
    const classes = useStyles()
    const prettyOptions = {
        mode: { name: 'workload-mode', type: 'text/html' },
        lineNumbers: false,
        cursorHeight: 1.25,
        fullScreen: false,
        scrollbarStyle: null,
        lineWrapping: false,
        extraKeys: {Tab: false, "Shift-Tab": false},
        theme: 'neat',
        readOnly: props.disabled ? "nocursor" : false
    }

    const handleClickAway = () => {
        setOpen(false)
    }

    const handleChange2 = (editor, data, value) => {
        //console.log('pre value is: ',value)
        setValue(value)
        //console.log('post value is: ',value )
        const cursor = editor.getSearchCursor(props.schema.dynamic ? widgetRe2 : widgetRe, 0, { multiline: 'disable' })
       
        while (cursor.findNext()) {
            
            const stringMatch = editor.getRange(cursor.from(), cursor.to()).match(props.schema.dynamic ? widgetRe2 : widgetRe)[1]
            const doc = editor.getDoc()
           
            var currNode = document.createElement('span')

            try {
                const refInfo = props.schema.dynamic ? null : stringMatch.split('.') //format nodes.{nodeID}.outputFields.{refString...}
                if (props.schema.dynamic){

                    var fromObj = cursor.from()
                    var toObj = cursor.to()
                  
                ReactDOM.render(<DynamicChip deleteClick={() => doc.replaceRange("", fromObj, toObj)} icon={nodeData.data.icon}  label={value}  />, currNode)
                    
                } else {
                    
                const refNodeID = refInfo[1]
                const testDataRefString = refInfo.slice(3).join('.')
                const refNode = parents[refNodeID]
                const matchData = Object.byString(refNode.data.testData, testDataRefString)

                var fromObj = cursor.from()
                var toObj = cursor.to()
               
                ReactDOM.render(<ExecutionChip deleteClick={() => doc.replaceRange("", fromObj, toObj)} icon={refNode.data.icon} step={refNode.data.label} label={refInfo[refInfo.length - 1]} cvalue={(!Array.isArray(matchData) && !(typeof matchData === 'object')) ? matchData : JSON.stringify(matchData)} />, currNode)
                    }
                }
            catch (e) {
                console.log('failed rendering thing')
                console.log(e)
             
                ReactDOM.render(<ExecutionChip icon={null} step={'replacelabel'} label={"Unknown Reference"} cvalue={"N/A"} />, currNode)
            }

            editor.markText(cursor.from(), cursor.to(), { replacedWith: currNode })
        }
       
    }


    return (
        <ClickAwayListener key={id + 'cc'} onClickAway={handleClickAway}>
            <div  key={id + 'dv'} style={{ padding: '2px 8px 2px 8px' }} onFocus={(e) => {
                if (e.currentTarget === e.target) {
                    //    console.log('focused self');
                } else {
                    setOpen(true);
                    //    console.log('focused child', e.target);
                }

            }}>
                <div key={id + 'ddv'} id='codemirror-wrapper' className={classes.codemirror} >
                    <CodeMirror
                        id={props.id}
                        defaultValue={value}
                        value={value ?? ''}
                        // height='45px'
                        options={prettyOptions}
                        onChange={(editor, data, value) => { handleChange2(editor, data, value); props.onChange(value) }}
                        // onUpdate={(editor) => handleUpdate(editor)}
                        // onLoad={(editor) => {console.log('onload');handleChange2(editor, 1, value)}}
                        // onCursorActivity={updateSelection}
                        editorDidMount={(editor) => { console.log('onload'); handleChange2(editor, 1, value) }}
                        autoCursor={false}
                    />
                    {open === true ? <DropdownAccordion ky={id + '-dd'} parents={parents} currentNode={nodeId} styleClass={classes} sendData={ref => { console.log('sending ref\n' + ref); setValue((value ?? "") + '${' + ref + '}') }} /> : null}
                </div>
            </div>
        </ClickAwayListener>
    )
}

const CustomSelectWidget = (props, id, schemaObj, setFields, sendData = null, initVal, ky, styleClass) => {
    const parentTree = useSelector(selectParentTree);
   // console.log('parentTree is: ', parentTree)
   const nodeId = useSelector(selectCurrentElement);
   const parents = parentTree[nodeId];
    const [tabvalue, setTabValue] = React.useState(0);
    const [tablabel, setTablLabel] = React.useState(props.schema.title)
    const [expanded, setExpanded] = useState(false)
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const icon = <RadioButtonUncheckedOutlinedIcon fontSize="small" />;
    const checkedIcon = <RadioButtonCheckedOutlinedIcon fontSize="small" />;
    const [selected, setSelected] = React.useState('');
    const classes = useStyles();
    const handleTabChange = (newValue) => {
    
        setTabValue(newValue);
    };
    const hoverOn = (value) => {
        setSelected(value);
      }
    
      const hoverOff = () => {
        setSelected(!selected);
      }
      const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
       const filteredData =  props.schema.enum.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        })
        setFilteredResults(filteredData)
        } 
        else {
            setFilteredResults(props.schema.enum)
        }
    }
    console.log('props for the enums are: ', props.schema)
    if (props.schema.enum.length > 0) {
        console.log('enum length is great than 0...')
        function TabPanel(props) {
            const { children, value, index, ...other } = props;
          
            return (
              <div key={index + 'tab'}
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
              >
                {value === index && (
                  <Box p={3}>
                    <Typography>{children}</Typography>
                  </Box>
                )}
              </div>
            );
          }
          
          TabPanel.propTypes = {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
          };
          function a11yProps(index) {
            return {
              id: `simple-tab-${index}`,
              'aria-controls': `simple-tabpanel-${index}`,
            };
          }
          return (
       
            <div key={'index'} className={classes.dropdown} >
            
            <Tabs style={{ marginBottom: 10 }}
                    value={tabvalue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                >
                    <Tab onClick={() => {handleTabChange(0)}} {...a11yProps(0)} style={{textTransform: 'none'}} label={tablabel} />
                    <Tab onClick={() => handleTabChange(1)} {...a11yProps(1)} style={{textTransform: 'none'}} label="Custom" />
                    
                </Tabs>
               {tabvalue === 0 ? <TextField onChange={(e) => searchItems(e.target.value)}  style={{ width: '100%', height: '35px', marginBottom: '25px'}} id="outlined-search" label="Search..." type="search" variant="outlined"
                value={searchInput}
                key='dynamic-search'
               
                placeholder='Search...' 
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRoundedIcon style={{color: 'gray'}} />
                      </InputAdornment>
                    ),
                  }} /> : null }
                <Divider variant="middle" />
                <TabPanel value={tabvalue} index={0}>
               
                {props.schema.enum.length > 0 ? searchInput.length > 1 ? filteredResults.map(option => <div><MenuItem className={classes.radio} id={option} key={option} onMouseEnter={() => hoverOn(option)}
                onMouseLeave={hoverOff} onClick={() => { sendData(option); setExpanded(false) }}>
                
                <Radio
                  id={option}
                  key={option}
                  color='primary'
                  checkedIcon={checkedIcon}
                  icon={icon}
                  style={{ marginRight: 8 }}
                  checked={selected === option}
                  value={option}
                />
                <Typography variant='inherit' >{option} </Typography>
                
              </MenuItem> <Typography style={{marginTop: '-17px', marginLeft: '62px', fontSize:'11px', color: 'gray'}} align='left' variant="caption" display="block" gutterBottom>{option.id}</Typography>
              </div>
              ): props.schema.enum.map(option => <div><MenuItem className={classes.radio} id={option} key={option} onMouseEnter={() => hoverOn(option)}
                onMouseLeave={hoverOff} onClick={() => { sendData(option); setExpanded(false) }}>
                
                <Radio
                  id={option}
                  key={option}
                  color='primary'
                  checkedIcon={checkedIcon}
                  icon={icon}
                  style={{ marginRight: 8 }}
                  checked={selected === option}
                  value={option}
                />
                <Typography  variant='inherit' >{option}<br/><div style={{ fontSize:'13px', fontWeight: 400, color: 'gray'}}>{option}</div> </Typography>
                
               </MenuItem>
              </div>
              ): <div>
              <LinearProgress color="primary" />
              <Skeleton animation="wave" height={34} style={{ marginTop: 36 }} /> 
              <Skeleton animation="wave" height={18} style={{ marginTop: 6 }} /> 
              <Skeleton animation="wave" height={85} style={{ marginBottom: 6 }} /> 
              <Skeleton animation="wave" height={34} style={{ marginTop: 36 }} /> 
              <Skeleton animation="wave" height={18} style={{ marginTop: 6 }} /> 
              <Skeleton animation="wave" height={85} style={{ marginBottom: 6 }} /> 
              </div>
            }
              
               </TabPanel>
                <TabPanel value={tabvalue} index={1}>
                <Typography style={{ fontSize: 20, fontWeight: 900, marginLeft: 25, paddingTop: 15, paddingBottom: 10, borderTop: '1px solid lightgray' }}>Insert Data...</Typography>
                {parents ? Object.entries(parents).map(([k, v], i) => {
                    console.log('parent Tree is now: ',parentTree)
                    return (
                        <div>
                        
                            <Accordion key={k + '-accordion'}>
                                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} key={k + '-accordionsummary'}>
                                    <Avatar alt={v.data.label} src={v.data.icon} className={classes.small} /><Typography key={i} className={classes.accordionheading}>{i+1}. {v.data.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails key={k + '_' + ky}>
                                    <div className={classes.chiproot}>
                                        {!v.data.testData ? <Typography style={{ marginLeft: '5%', color: 'white', fontWeight: 'bold', backgroundColor: 'red', borderRadius: '20px', padding: '0px 6px 0px 6px' }} >Error: No data to insert...</Typography> : <Inspector2 id={k} key={k} data={v.data.testData} isExpanded={(keypath, query) => true} onClick={(e) => { console.log(e); sendData('nodes.' + k + '.outputFields' + (e.path != 't' ? '.' + e.path.slice(2) : '')) }}></Inspector2>}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Divider />
                        </div>
                    )
                }) : null}</TabPanel>
            </div> 
        )

    }


}



const ArrayFieldTemplate = (props)=> {

  const buttonRef = useRef(null);
  const buttonRef2 = useRef(null);
  const [counter, setCounter] = useState(0);

  /* function clickHandler(event) {
    setCounter((prev) => prev + 1);
  } */
  /* useEffect(() => {
   // console.log('counter === ', counter)
    if (typeof props.formData[0] === 'object' && props.formData[0] !== null ) {
   // console.log('props in the IF: ', props)
    }
    else {
      //  console.log('props in the ELSE: ', props)
    buttonRef.current.addEventListener('click', clickHandler);
    buttonRef.current.click();
    }
    return () => { 
    
    };
  }, []); */
  
    return (
        <div style={{border: '1px solid black', borderRadius: '5px', padding: '8px', margin: '8px'}}>
        <Typography variant="h5">{props.schema.title}</Typography>
        <Typography>{props.schema.description}</Typography>
        {props.items &&
          props.items.map(element => (
            <div key={element.key} className={element.className} >
              <div>{element.children}</div>
              <div style={{float: "right"}}>
              {element.hasMoveDown && (
                
                  <IconButton  style={{cursor: "pointer", backgroundColor: 'white', color: 'black', padding: '8px 12px 8px 12px'}}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index + 1
                  )}>
                    <Tooltip title="Move down" placement="top"><ArrowDownwardRoundedIcon /></Tooltip>
                  
                </IconButton>
              )}
              {element.hasMoveUp && (
                  <IconButton  style={{cursor: "pointer", backgroundColor: 'white', color: 'black', padding: '8px 12px 8px 12px'}}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index - 1
                  )}>
                  <Tooltip title="Move up" placement="top"><ArrowUpwardRoundedIcon /></Tooltip>
                </IconButton>
              )}
              
              <IconButton id="deletebutton" ref={buttonRef2} style={{cursor: "pointer", backgroundColor: 'white', color: 'black', padding: '8px 12px 8px 12px', border: '1px solid black', borderRadius: '5px', marginBottom: '8px'}}
               onClick={element.onDropIndexClick(element.index)}>
              <Tooltip title="Remove this item" placement="top"><ClearRoundedIcon /></Tooltip>
              </IconButton>
              
              
              </div>
              <hr style={{marginTop: '8px', marginBottom: '8px', width: "100%"}} />
            </div>
      
          ))}
  
        {props.canAdd && (
          <div className="row">
            <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
              <Button ref={buttonRef} id="addbutton" onClick={props.onAddClick} type="button" variant="contained" style={{textTransform: "none", color: "white", backgroundColor: "black", float: "right", padding: '4px 8px 4px 8px', marginBottom: '8px'}}>
                + Add to {props.schema.title}
              </Button>
            </p>
          </div>
        )}
      </div>
    );
    
  }

const WorkflowWidget = ( props, id, setFields, initVal, schemaObj, sendData = null) => {
    const [formloading, setFormLoading] = useState(true)
    const [schema, setSchema] = useState(schemaObj);
    const [value, setValue] = useState(props.value);  
    const [selectionStart, setSelectionStart] = useState();
    const [nodes, setNodes] = useState([]);
    const [open, setOpen] = useState(false);
    const parentTree = useSelector(selectParentTree);
    const nodeId = useSelector(selectCurrentElement);
    const nodeData = useSelector(selectElementById(nodeId))
    //const workflowData = useSelector(selectWorkflowTemplateNodes)
    //console.log('nodeData is: ', nodeData.type)
    const parents = parentTree[nodeId];
    // console.log('TREE are (line 152) ,',parentTree)
    
    //console.log('workflowwidget props are: ', props)
   
    const [CM, setCM] = useState(null)

    const widgetRe =  /\${(([^}][^}]?|[^}]}?)*)}/;
    const widgetRe2 = /[\s\S]+/;
    const classes = useStyles()
    const prettyOptions = {
        mode: { name: 'workload-mode', type: 'text/html' },
        lineNumbers: false,
        fullScreen: false,
        scrollbarStyle: null,
        lineWrapping: false,
        theme: 'neat',
        extraKeys: {Tab: false, "Shift-Tab": false},
       // readOnly: props.schema.dynamic ? true : false
    }
    
   

    const handleClickAway = () => {
        setOpen(false)
    }

    const [cursorpos, setCursorPos] = useState()
    const updateValue = (ref) => {
       // console.log('cursorpos is: ', cursorpos)
        //console.log('ref is: ', ref)
       // console.log('updateValue is: ', value)
        var rows =  value ? value.replace(/\r\n/g,"\n").split("\n") : [""]
       // console.log('rows is: ', rows)
        const str = value ?? "";
        const index = cursorpos ? cursorpos.ch : 0;
        const line = cursorpos ? cursorpos.line : 0;
        var selection = rows[line]
        //console.log('selection: ', selection)
        const left = selection.slice(0, index);
        const right = selection.slice(index);
       // console.log('left side text: ', left)
        //console.log('right side text: ', ref + right)
        var newval = left+ref+right
        //console.log('new val is: ', newval)
        
        rows.splice(line,1,newval)
        var new_rows = rows.join("\n")
     //   console.log('new rows is: ', new_rows)
        
        setValue(new_rows)
    }

    const handleChange2 = (editor, data, value) => {
        //console.log('pre value is: ',value)
        setValue(value)
        //console.log('post value is: ',value )
        const cursor = editor.getSearchCursor(props.schema.dynamic ? widgetRe2 : widgetRe, 0, { multiline: 'disable' })
       
        while (cursor.findNext()) {
            
            const stringMatch = editor.getRange(cursor.from(), cursor.to()).match(props.schema.dynamic ? widgetRe2 : widgetRe)[1]
            const doc = editor.getDoc()
           
            var currNode = document.createElement('span')

            try {
                const refInfo = props.schema.dynamic ? null : stringMatch.split('.') //format nodes.{nodeID}.outputFields.{refString...}
                if (props.schema.dynamic){

                    var fromObj = cursor.from()
                    var toObj = cursor.to()
                  
                ReactDOM.render(<DynamicChip deleteClick={() => doc.replaceRange("", fromObj, toObj)} icon={nodeData.data.icon}  label={value}  />, currNode)
                    
                } else {
                    
                const refNodeID = refInfo[1]
                const testDataRefString = refInfo.slice(3).join('.')
                console.log('testdata ref string: ', testDataRefString)
                const refNode = parents[refNodeID]
                const matchData = Object.byString(refNode.data.testData, testDataRefString)
                //console.log('refNodeID is: ', refNodeID)
                //console.log('opened node ID is: ', refInfo)
                var fromObj = cursor.from()
                var toObj = cursor.to()
              // console.log('refNodeID is: ', parents.indexOf(refNodeID))
               
                ReactDOM.render(<WorkflowChip deleteClick={() => doc.replaceRange("", fromObj, toObj)} icon={refNode.data.icon} step={null} label={refInfo[refInfo.length - 1]} cvalue={(!Array.isArray(matchData) && !(typeof matchData === 'object')) ? matchData : JSON.stringify(matchData)} />, currNode)
                    }
                }
            catch (e) {
                console.log('failed rendering thing')
                console.log(e)
             
                ReactDOM.render(<WorkflowChip icon={null} step={'replacelabel'} label={"Unknown Reference"} cvalue={"N/A"} />, currNode)
            }

            editor.markText(cursor.from(), cursor.to(), { replacedWith: currNode })
        }
       
    }

const handleDynamicValueChange = (e) => {
    setValue(null);
   // console.log(typeof e)
   
    if (typeof e !== 'string'){
        if (e === null || undefined) {
            setValue('')
        } else {
      //  console.log('not string')
        setValue(e.toString())
        }
    } else {
      //  console.log('is string')
        setValue(e);
    }
  
    handleClickAway();
}

    return (

       
      
        <ClickAwayListener key={'cc'+id} onClickAway={handleClickAway}>
       
            <div key={'base'+id} style={{ padding: '2px 8px 2px 8px' }} onFocus={(e) => {
                if (e.currentTarget === e.target) {
                    //    console.log('focused self');
                } else {
                    setOpen(true);
                    //    console.log('focused child', e.target);
                }

            }}>
           
                <Typography key={props.id} style={{fontWeight: 'bold'}}>{props.schema.title}</Typography>
                <div  key={"codemirror_wrap" + id} id='codemirror-wrapper' className={classes.codemirror} >
                
                    <CodeMirror
                        key={props.id}
                        id={props.id}
                        defaultValue={value ?? ''}
                        value={value ? value.toString() : ''}
                        options={prettyOptions}
                        autoCursor={false}
                        onCursor={(editor) => { setCursorPos(editor.getCursor())} }
                        onBlur={(editor, data, value) => {
                           // console.log('blurrred')
                        }}
                        onChange={(editor, data, value) => { handleChange2(editor, data, value); props.onChange(value);  }}
                        editorDidMount={(editor) => { console.log('onload');  handleChange2(editor, 1, value );setFormLoading(false); }}
                        
                    />  {props.schema.dynamic ? <UnfoldMoreRoundedIcon style={{ float: 'right',display: 'flex', position: 'absolute', right: 0, margin: '0 auto',justifyContent: 'right',alignItems: 'right',marginTop: '-30px', paddingRight: '14px', zIndex: 6}} /> : null }
                    {open === true ? props.schema.dynamic ? <DynamicDropdownAccordion setFields={setFields} open={true} props={props} ky={id + '-dd'} parents={parents} currentNode={nodeId} styleClass={classes}  sendData={ref => { console.log('sending ref\n' + ref); {typeof ref === 'string' ? ref.includes("nodes") ? handleDynamicValueChange('${' + ref + '}') : handleDynamicValueChange(ref) : handleDynamicValueChange(ref) }  }} /> : nodeData.type === 'trigger' ? null : <DropdownAccordion props={props} ky={id + '-dd'} parents={parents} currentNode={nodeId} styleClass={classes} sendData={ref => { console.log('sending ref\n' + ref); updateValue('${' + ref + '}' ) }} /> : null}
                </div>
        
            </div>
        
        </ClickAwayListener> 
        
    )
}

const muiTheme = createMuiTheme({
    palette: {
        primary: { main: '#0069ea' },
        secondary: { main: '#ff5555' }
    },
    props: {
        MuiTextField: {
            variant: 'outlined'
        }
    }
});

function DropdownAccordion({parents, ky, currentNode, sendData = null, styleClass }) {
   // const [expanded, setExpanded] = useState(false)
    const width = window.innerWidth;
    let breakpoint = ''

if (width < 450) {
    breakpoint = 'small'
} else if (width < 720 && width > 449) {
    breakpoint = 'medium'
} else if (width > 721) {
    breakpoint = 'large'
}

const [stepExpand, setStepExpand] = useState()
    
    return (
        <div key={'new_div'+ky} className={styleClass.dropdown} >
        <Typography key={ky} style={{ fontSize: 20, fontWeight: 900, marginLeft: 25, paddingTop: 15, paddingBottom: 10, borderBottom: '1px solid lightgray' }}>Insert Data...</Typography>

            {parents ? Object.entries(parents).map(([k, v], i) => {
                return (
                    <div key={k + 'dvvv'}>

                        <Accordion expanded={stepExpand === k ? true : false} key={k + '-accordion'} onClick={() => setStepExpand(k)}>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} key={k + '-accordionsummary'}>
                                <Avatar alt={v.data.app} src={v.data.icon} className={styleClass.small} /><div key={k + 'dvia'} style={{width: breakpoint === 'small' ? '140px' : '90%'}}><Typography key={ky} noWrap style={{ fontSize: breakpoint === 'small' ? '12px' : null}} className={styleClass.accordionheading}>{i+1}. {v.data.name}</Typography></div>
                            </AccordionSummary>
                            <AccordionDetails key={k + '_' + ky}>
                                {stepExpand === k ? 
                                <div key={k + 'dvvvvvvv'} className={styleClass.chiproot}>
                                    {!v.data.testData ? <Typography key={ky} style={{ marginLeft: '5%', color: 'white', fontWeight: 'bold', backgroundColor: 'red', borderRadius: '20px', padding: '0px 6px 0px 6px' }} >Error: No data to insert...</Typography> : <Inspector2 id={k} key={k} data={v.data.testData} isExpanded={(keypath, query) => true} onClick={(e) => { console.log(e);

const formattedPath = 'nodes.' + k + '.outputFields' + (e.path !== 't' ? '.' + e.path.slice(2) : '')

 sendData(formattedPath)}}></Inspector2>}
                                </div>
                                :
                                <Typography>Loading...</Typography>
            }
                            </AccordionDetails>
                        </Accordion>
                        <Divider key={ky} />
                    </div>
                )
            }) : null}
        </div>
    )
}



function DynamicDropdownAccordion({open, props, parents, ky, setFields, sendData = null, styleClass }) {
    const [expanded, setExpanded] = useState(false)
    const [tabvalue, setTabValue] = React.useState(0);
    const workflow_id = useSelector(selectWorkflowId)
    const nodeId = useSelector(selectCurrentElement);
    const nodeData = useSelector(selectElementById(nodeId))
    const icon = <RadioButtonUncheckedOutlinedIcon fontSize="small" />;
    const checkedIcon = <RadioButtonCheckedOutlinedIcon fontSize="small" />;
    const [selected, setSelected] = React.useState('');
    const classes = useStyles();
    const dynamic_arr = props.schema.dynamic.split(".")
    const tablabel = dynamic_arr[1]
    const dynamic_app = dynamic_arr[0]
    const dynamic_action = dynamic_arr[2]
    const dynamic_head = dynamic_arr[3]
    const dynamic_key = dynamic_arr[4]
    const dynamic_value = dynamic_arr[5]
    const params = props.schema.params
    const perform = props.schema.perform
    const [paginationUrl, setPaginationUrl] = useState()
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    //console.log('dd perform are: ', perform)
    const [dd, setDD] = React.useState()
    const [load_dd, setLoadDD] = React.useState(open)
    const [ddLoading, setddLoading] = useState(false)
    const [onerror, setOnError] = useState(false)
    const [errormessage, setErrorMessage] = useState()
    const dispatch = useDispatch();
    
    const handleTabChange = (newValue) => {
    
        setTabValue(newValue);
    };
    const hoverOn = (value) => {
        setSelected(value);
      }
    
      const hoverOff = () => {
        setSelected(!selected);
      }
      const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
       const filteredData =  dd.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        })
        setFilteredResults(filteredData)
        } 
        else {
            setFilteredResults(dd)
        }
    }

    useEffect(() => {
       // console.log('dd is: ', dd)
    }, [dd])

const getDD = (url) => {
   // console.log('getting dd...')

   if (url) {
   // console.log('we got a url field here: ', url)
   setddLoading(true)
    var djangoData = {
        operation: 'getDD',
        data: {
            url: nodeData.data.url,
            app: dynamic_app,
            credential: nodeData.data.credential,
            action: 'loadmore',
            fields: [{url: url}],
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }
    axios(config)
    .then(function (response) {
      //  console.log('options are: ',response.data);
      if (dynamic_head === '0'){
       
        var choicesProperties = response.data.map(choice => {
            let properties = {
              id : choice[dynamic_key],
              name : choice[dynamic_value]
            };
           
            return properties;
           });
      } else {
       
        var choicesProperties = response.data[dynamic_head].map(choice => {
            let properties = {
              id : choice[dynamic_key],
              name : choice[dynamic_value]
            };
           
            return properties;
           });
        }
        var arr = dd
        arr = arr.concat(choicesProperties)
       // console.log('arr = ', arr)
        setDD(arr);
        setddLoading(false)
        let nextPage = response.data.links
       if (nextPage[0] && nextPage[0].hasOwnProperty('href')) {
        var pagUrl = response.data.links[0].href
        setPaginationUrl(pagUrl) 
    } else { setPaginationUrl()}
   })
} else {
    setddLoading(true)
    let replaced_params = []
    if (params) {
       // console.log('params exist, lets look up some shit...')
        params.map((param) => { 
        const result = nodeData.data.fields.filter(x => x.name === param.name);
//console.log('node data dot fields....: ',nodeData.data.fields)

     //  console.log('result:', result);
       if (result[0]) {
       // console.log('param if: ', param)
        replaced_params.push({'name':param.name,'in':'path','value': result[0].value})
     //   console.log('replaced_params: ', replaced_params)
       } else {
       // console.log('param else: ', param)
       // console.log('result was empty....gotta do somethin else')
        const body_value = nodeData.data.fields[0].value[param.name]
       // console.log('body result: ', body_value)
        replaced_params.push({'name':param.name,'in':'path','value': body_value})
       }

    })
    }
    var djangoData = {
        operation: 'getDD',
        data: {
            url: nodeData.data.url,
            app: dynamic_app,
            credential: nodeData.data.credential,
            action: dynamic_action,
            fields: replaced_params,
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }
    axios(config)
    .then(function (response) {
      //  console.log('options are: ',response.data);
      if (dynamic_head === '0'){
       
        var choicesProperties = response.data.map(choice => {
            let properties = {
              id : choice[dynamic_key],
              name : choice[dynamic_value]
            };
           
            return properties;
           });
      } else {
       
        var choicesProperties = response.data[dynamic_head].map(choice => {
            let properties = {
              id : choice[dynamic_key],
              name : choice[dynamic_value]
            };
           
            return properties;
           });
        }
         //  console.log(choicesProperties);
          setDD(choicesProperties);
          setOnError(false)
          setddLoading(false)
          if (response.data.links && response.data.links[0].href) {
            var pagUrl = response.data.links[0].href
            setPaginationUrl(pagUrl)
        }
    })
.catch(function (error) {
    console.log(error);
    setErrorMessage(JSON.stringify(error.message))
   setOnError(true)
    setddLoading(false)
   
    
});
}
}

if (load_dd === true){
    getDD()
   setLoadDD(false)
}

const sendDynamicFormSignal = (e) => {
   // console.log('sending dynamic form signal to server ', e)
    setLoadDD(true)
    dispatch(loadingperform(true))
    sendData(e); 
    var perform_split = perform.split(".")
  
    var site = perform_split[0]
    var field_title = perform_split[1]
    var perform_action = perform_split[2]
    var perform_data_head = perform_split[3]
    var perform_key = perform_split[4]
    var perform_value = perform_split[5]
    var perform_property_name = perform_split[6]
  //  console.log('perform propert name === ', perform_property_name)
    let replaced_params = []

    if (params) {
       // console.log('params exist, lets look up some shit...')
        params.map((param) => { 
        const result = nodeData.data.fields.filter(x => x.name === param.name);
        replaced_params.push({'name':param.name,'in': 'path', 'value': result[0].value === undefined ? e : result[0].value})
    })
    }
   // console.log('replaced_params: ', replaced_params)
    var djangoData = {
        operation: 'getDD',
        data: {
            url: nodeData.data.url,
            app: dynamic_app,
            credential: nodeData.data.credential,
            action: perform_action,
            fields: replaced_params,
        }
    }

    var config = {
        method: 'post',
        url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/djangocaller',
        headers: {
            'Content-Type': 'application/json'
        },
        data: djangoData
    }
    axios(config)
    .then(function (response) {
     //   console.log('options are: ',response.data);
        if (perform_data_head === '0'){
       
            var choicesProperties = response.data.map(choice => {
                let properties = {
                  name : choice[perform_key],
                  title : choice[perform_value]
                };
               
                return properties;
               });
          } else {
           
            var choicesProperties = response.data[perform_data_head].map(choice => {
                let properties = {
                  name : choice[perform_key],
                  title : choice[perform_value]
                };
               
                return properties;
               });
            }
           
            
            if (perform_data_head !== '0') {
               
                var data_start = nodeData.data.fields
                let new_data_start = Object.assign([{}], data_start)
                
              //  console.log('data_start: ', data_start)
              //  console.log('new data start: ', new_data_start)
                var nds = replaced_params.map((param) => { 
                    var r = new_data_start.find(x => {  return x.name === param.name });
                  //  console.log('result is: ', r)
                    
                    var new_new_data_start = Object.assign({}, r, {value: param.value})
                    return new_new_data_start
                  
                   // updated_obj.push(result)
                })
              
                let newdatastart = Object.assign(new_data_start, nds)
                //console.log('newnew data start: ', newdatastart)
                let result = newdatastart.find(obj => {
                    return obj.name === 'body'
                  })
                   if (!result) {
                   // console.log('result is empty...apending to the fields...')
                    //console.log('current newdata start::: ', newdatastart)
                    const empty_result = {description: " ",in: "path", name: "body", title: " ", value: {}}
                    newdatastart = [...newdatastart, empty_result]
                    //console.log('data start is now:: ', newdatastart)
                    result = empty_result
                    dispatch(elementUpdated(nodeData.id,
                        {
                            name: nodeData.data.name,
                            type: nodeData.data.app,
                            actionlabel: nodeData.data.actionlabel,
                            label: nodeData.data.label,
                            icon: nodeData.data.icon,
                            hasauth: nodeData.data.hasauth,
                            action: nodeData.data.action,
                            credential: nodeData.data.credential,
                            authname: nodeData.data.authname,
                            fields: newdatastart,
                            testData: nodeData.data.testData,
                            url: nodeData.data.url
                        },
                        null
                    ));
                   // dispatch(saveWorkflow(1))
                  } 
                //Object.assign({},result, {result: {value:  [{typecast: true}]}})

                  if (perform_property_name !== '0') {
                  var new_data_head = {[perform_property_name ? perform_property_name : perform_data_head]: {'description': field_title, 'title': field_title,'type': 'object', 'properties':{}}}
                //  console.log('new_data_head: ', new_data_head)
                  var new_properties = choicesProperties.map(choice => {
                   let n_p = {
                       [choice.name]: {
                            'description': choice.title,
                            'title': choice.title,
                            'type': 'string'
                        }
                    }
                    Object.assign(new_data_head[perform_property_name ? perform_property_name : perform_data_head].properties, n_p)
                    return new_properties
                    
                  })
                } if (perform_property_name === '0') {
                    var new_data_head = {}
                  //console.log('new_data_head: ', new_data_head)
                  var new_properties = choicesProperties.map(choice => {
                   let n_p = {
                       [choice.name]: {
                            'description': choice.title,
                            'title': choice.title,
                            'type': 'string'
                        }
                    }
                    Object.assign(new_data_head, n_p)
                    return new_properties
                    
                  })
                }
               
               
               // console.log('finalForm: ', new_data_head)
                //console.log('result: ', result)
                const new_obj = { ...result, schema: { properties:  new_data_head } }
                //console.log('new obj is: ', new_obj)
              // special line for Airtable performs  const newnew_obj = Object.assign({}, new_obj, {value: {typecast: true}} )
                const newnew_obj = Object.assign({}, new_obj)

               // console.log('newnew obj is: ', newnew_obj)

                const res = newdatastart.map(obj => [newnew_obj].find(o => o.name === obj.name) || obj);

               //  console.log('whole shebang 1: ', res);
                
                //console.log('whole shebang 2: ', nodeData)
                const new_nodeData = res
               // console.log('new node data:: ', new_nodeData)
                dispatch(elementUpdated(nodeData.id,
                    {
                        name: nodeData.data.name,
                        type: nodeData.data.app,
                        actionlabel: nodeData.data.actionlabel,
                        label: nodeData.data.label,
                        icon: nodeData.data.icon,
                        hasauth: nodeData.data.hasauth,
                        action: nodeData.data.action,
                        credential: nodeData.data.credential,
                        authname: nodeData.data.authname,
                        fields: new_nodeData,
                        testData: nodeData.data.testData,
                        url: nodeData.data.url
                    },
                    null
                ));
                dispatch(saveWorkflow(1))
              
                
                
            } else {
                var data_start = nodeData.data.fields
                const new_data_start = Object.assign([{}], data_start)
                
              //  console.log('data_start: ', data_start)
              //  console.log('new data start: ', new_data_start)
                var nds = replaced_params.map((param) => { 
                    var r = new_data_start.find(x => {  return x.name === param.name });
                  //  console.log('result is: ', r)
                    
                    var new_new_data_start = Object.assign({}, r, {value: param.value})
                    return new_new_data_start
                  
                   // updated_obj.push(result)
                })
              
                const newdatastart = Object.assign(new_data_start, nds)
               // console.log('newnew data start: ', newdatastart)
                let result = newdatastart.find(obj => {
                    return obj.name === 'body'
                  })

    
                  let new_data_head = {'description': field_title, 'title': field_title,'type': 'object', 'properties':{}}
                //  console.log('new_data_head: ', new_data_head)
                  var new_properties = choicesProperties.map(choice => {
                   let n_p = {
                       [choice.name]: {
                            'description': choice.title,
                            'title': choice.title,
                            'type': 'string'
                        }
                    }
                    Object.assign(new_data_head.properties, n_p)
                    return new_properties
                    
                  })
               
                
                console.log('finalForm: ', new_data_head)
                const new_obj = { ...result, schema: { properties:  new_data_head } }
                const res = newdatastart.map(obj => [new_obj].find(o => o.name === obj.name) || obj);

                // console.log('whole shebang 1: ', res);
                 
               // console.log('whole shebang 2: ', nodeData)
                const new_nodeData = res
                
                dispatch(elementUpdated(nodeData.id,
                    {
                        name: nodeData.data.name,
                        type: nodeData.data.app,
                        actionlabel: nodeData.data.actionlabel,
                        label: nodeData.data.label,
                        icon: nodeData.data.icon,
                        hasauth: nodeData.data.hasauth,
                        action: nodeData.data.action,
                        credential: nodeData.data.credential,
                        authname: nodeData.data.authname,
                        fields: new_nodeData,
                        testData: nodeData.data.testData,
                        url: nodeData.data.url
                    },
                    null
                ));
                dispatch(saveWorkflow(1))
              
            }
                 
            setLoadDD(false)
            dispatch(loadingperform(false))
          

    })
.catch(function (error) {
   console.log(error);
   setLoadDD(false)
  
});


}

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div key={index + 'tab'}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
      };
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
    return (
       
        <div key={ky} className={styleClass.dropdown} >
        
        <Tabs style={{ marginBottom: 10 }}
                value={tabvalue}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
            >
                <Tab onClick={() => {getDD(); handleTabChange(0)}} {...a11yProps(0)} style={{textTransform: 'none'}} label={tablabel} />
                <Tab onClick={() => handleTabChange(1)} {...a11yProps(1)} style={{textTransform: 'none'}} label="Custom" />
                
            </Tabs>
           {tabvalue === 0 ? <TextField onChange={(e) => searchItems(e.target.value)}  style={{ width: '100%', height: '35px', marginBottom: '25px'}} id="outlined-search" label="Search..." type="search" variant="outlined"
            value={searchInput}
            key='dynamic-search'
           
            placeholder='Search...' 
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon style={{color: 'gray'}} />
                  </InputAdornment>
                ),
              }} /> : null }
            <Divider variant="middle" />
            <TabPanel value={tabvalue} index={0}>
           
            {onerror === true ? <>
            <Chip color="secondary" label="Error:" />
            <Typography>{errormessage}</Typography>
            </>
            : dd ? searchInput.length > 1 ? filteredResults.map(option => <div><MenuItem className={classes.radio} id={option.id} key={option.id} onMouseEnter={() => hoverOn(option.id)}
            onMouseLeave={hoverOff} onClick={() => { sendData(option.id); setExpanded(false) }}>
            
            <Radio
              id={option.id}
              key={option.id}
              color='primary'
              checkedIcon={checkedIcon}
              icon={icon}
              style={{ marginRight: 8 }}
              checked={selected === option.id}
              value={option.id}
            />
            <Typography variant='inherit' >{option.name} </Typography>
            
          </MenuItem> <Typography style={{marginTop: '-17px', marginLeft: '62px', fontSize:'11px', color: 'gray'}} align='left' variant="caption" display="block" gutterBottom>{option.id}</Typography>
          </div>
          ): dd.map(option => <div><MenuItem className={classes.radio} id={option.id} key={option.id} onMouseEnter={() => hoverOn(option.id)}
            onMouseLeave={hoverOff} onClick={() => { perform ?  sendDynamicFormSignal(option.id) : sendData(option.id); setExpanded(false) }}>
            
            <Radio
              id={option.id}
              key={option.id}
              color='primary'
              checkedIcon={checkedIcon}
              icon={icon}
              style={{ marginRight: 8 }}
              checked={selected === option.id}
              value={option.id}
            />
            <Typography  variant='inherit' >{option.name}<br/><div style={{ fontSize:'13px', fontWeight: 400, color: 'gray'}}>{option.id}</div> </Typography>
            
           </MenuItem>
          </div>
          ): <div>
          <LinearProgress color="primary" />
          <Skeleton animation="wave" height={34} style={{ marginTop: 36 }} /> 
          <Skeleton animation="wave" height={18} style={{ marginTop: 6 }} /> 
          <Skeleton animation="wave" height={85} style={{ marginBottom: 6 }} /> 
          <Skeleton animation="wave" height={34} style={{ marginTop: 36 }} /> 
          <Skeleton animation="wave" height={18} style={{ marginTop: 6 }} /> 
          <Skeleton animation="wave" height={85} style={{ marginBottom: 6 }} /> 
          </div>
        }
          <div style={{marginTop: '10px',width: '100%', position:'absolute',zIndex: 9999}}><Button disabled={paginationUrl ? 0 : 1} style={{ marginBottom: '15px', width: '40%', textTransform: 'none',fontWeight: 600, fontSize: '16px', backgroundColor: paginationUrl ? 'white' : 'whitesmoke', color: paginationUrl ? 'black' : 'gray'}} variant='outlined' onClick={() => getDD(paginationUrl)} >{ddLoading ? <CircularProgress color='default' size={28} /> : 'Load More'}</Button> <Button style={{ marginBottom: '15px', width: '40%', textTransform: 'none',fontWeight: 600, fontSize: '16px', backgroundColor: 'white', color:'black'}} variant='outlined' onClick={() => sendData(null)}>Clear Data</Button></div>
           </TabPanel>
            <TabPanel value={tabvalue} index={1}>
            <Typography style={{ fontSize: 20, fontWeight: 900, marginLeft: 25, paddingTop: 15, paddingBottom: 10, borderTop: '1px solid lightgray' }}>Insert Data...</Typography>
            {parents ? Object.entries(parents).map(([k, v], i) => {
                return (
                    <div>
                    
                        <Accordion key={k + '-accordion'}>
                            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} key={k + '-accordionsummary'}>
                                <Avatar alt={v.data.app} src={v.data.icon} className={styleClass.small} /><Typography key={i} className={styleClass.accordionheading}>{i+1}. {v.data.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails key={k + '_' + ky}>
                                <div className={styleClass.chiproot}>
                                    {!v.data.testData ? <Typography style={{ marginLeft: '5%', color: 'white', fontWeight: 'bold', backgroundColor: 'red', borderRadius: '20px', padding: '0px 6px 0px 6px' }} >Error: No data to insert...</Typography> : <Inspector2 id={k} key={k} data={v.data.testData} isExpanded={(keypath, query) => true} onClick={(e) => { console.log('eventits...', e); sendData('nodes.' + k + '.outputFields' + (e.path != 't' ? '.' + e.path.slice(2) : '')) }}></Inspector2>}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Divider />
                    </div>
                )
            }) : null}</TabPanel>
        </div> 
    )
}


export function FieldsRender({ breakpoint, fields, setValue = null,  setFields, disabled = false, source = "workflow" }) {
    //for each field, set the respective value back into the field and call it "value"
    // const dispatch = useDispatch();
    //console.log(fields)
    //console.log(Array.isArray(fields))
    // dispatch(ptree(parentTree));
    // dispatch(nodeid(nodeID));
    // console.log('parent tree from 404 is, ', parentTree)
    // console.log('nodeID from 405 is, ', nodeID)
    //console.log(fields)
    const [loaded, setLoaded] = useState(false)
    const hidden = () => <div style={{height: '1px', display: 'none'}} />

    const [uiSchema, setUiSchema] = useState({})

    var widgetSpec = {
        TextWidget: WorkflowWidget,
      
    }

 

    if (fields === undefined) {
      //  console.log('fields returned undefined....')
        return null
    }
    if (source == "execution") {
        widgetSpec = {
            TextWidget: ExecutionWidget
        }
    }
    fields = JSON.parse(JSON.stringify(fields)) //indicative of bigger problem? probably but works for now
if (fields && loaded === false) {
    const newItems = Object.assign({}, uiSchema);
   var body = fields.filter(x => x.in === 'formData')
  // console.log('body field is: ', body)
   if (body[0]?.hasOwnProperty('schema')) {
  var obj = body[0].schema?.hasOwnProperty('properties') ? body[0].schema.properties : body[0].schema.items.properties
 //console.log('obj is: ', obj)
   Object.keys(obj).forEach(key => {
    const value = obj[key]
   // console.log('keys are: ', key)
   // console.log('value is: ', value)
    if (value.hasOwnProperty('hidden') === true && value.hidden === true ){
        //console.log(key +" has a field called hidden...")
        newItems[key] = {"ui:field": hidden}
        
    }
   })
   }

  
  //newItems[] = {"ui:field": hidden};
 
  setUiSchema(newItems);
    setLoaded(true)

}


   // console.log('fields= ', fields)
  /*  const recursiveSearch = (obj, searchKey, results = []) => {
    const r = results;
    Object.keys(obj).forEach(key => {
       const value = obj[key];
       if(key === searchKey && typeof value !== 'object'){
          r.push(value);
          console.log('key: ', key, value)
       // setUiSchema({"url": {"ui:field": hidden}, "triggers": {"ui:field": hidden}})
       }else if(typeof value === 'object'){
          recursiveSearch(value, searchKey, r);
       }
    });
    return r;
 }; */

    return (
        <div>
            {
                fields && fields ? fields.map((field,i) => {

                  //  console.log('field properties are:')
                  //  console.log(field)
                  /*   if (field?.hasOwnProperty('value')) {
                     
                        Object.entries(field.value).forEach(key => {
                            if (Object.keys(key[1][0]).length === 0) {
                           
                              delete  field.value[key[0]]
                            }
                       })
                    } */
                   // console.log('i=', i)
                   // console.log('uischema is: ', uiSchema)
              
                // console.log(recursiveSearch(field, 'hidden'));
                    return (
                        
                       field.schema && !field.hidden || field.schema && field.hidden === false  ?
                            <div key={'div_'+i} >
                                <br key={i} />
                                <InputLabel key={'label-'+ i}><Typography key={'type-'+i} style={{ fontSize: 16, fontWeight: 700, color: 'black', marginLeft: '8px' }}>{field.required === true ? <span style={{color: 'red'}}>*  </span> : null }{field.title ?? field.description ?? field.name} {field.required === true ? <span style={{color: 'grey', fontWeight: 600, fontSize: '12px'}}> (required)</span> : null } </Typography></InputLabel>
                                <MuiThemeProvider key={'provider-'+i} theme={muiTheme}>
                                <Form breakpoint={breakpoint} key={'form-'+i} schema={field.schema} uiSchema={uiSchema} disabled={disabled} widgets={widgetSpec} ArrayFieldTemplate={ArrayFieldTemplate} noHTML5Validate formData={field.value} onChange={e => {
                                      //  console.log('eFormData: ', e.formData);
                                        field.value = e.formData;
                                        setFields([...fields])
                                    }}><Fragment key={'frag-'+i} /></Form>
                                </MuiThemeProvider>
                                <Typography key={'type-'+i} style={{ fontSize: '0.75rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)', letterSpacing: '0.03333em', lineHeight: '1.66', marginLeft: '8px', marginTop: '4px' }}>{field.description ?? ''}</Typography>
                            </div>
                            :
                            null
                    )
                })
                    : null
            }
        </div>
    )
}

export function DynamicFieldsRender({ configs, credential, initFields = [], initSelections = [], submitFunction }) {
    const classes = useStyles();
    const [fields, setFields] = useState(initFields)
    const [selections, setSelections] = useState(initSelections)
    const [loading, setLoading] = useState(false)
    const [dlabel, setDLabel] = useState(initSelections)
    const [selected, setSelected] = React.useState('');
    const icon = <RadioButtonUncheckedOutlinedIcon fontSize="small" />;
    const checkedIcon = <RadioButtonCheckedOutlinedIcon fontSize="small" />;

    const hoverOn = (value) => {
        setSelected(value);
      }
    
      const hoverOff = () => {
        setSelected(!selected);
      }

    //initialize fields
    useEffect(() => {
        if (fields && fields.length == 0 && configs !== null) {
            setLoading(true)
             axios(configs[0].asyncConfig([], credential)).then(resp => {
                console.log('asyncConfig response is: ')
                 console.log(resp)
                setFields([configs[0].responseTransform(resp)])
                setLoading(false)
            })
        }
    }, [])



    return (
        
      fields && fields.length > 0 ? fields.map((field, j) => {
        
            if (j + 1 >= configs.length) { return null }
            if (!configs[j + 1].finalProcess) {
                return (
                    <>
                        <Autocomplete
                            autoHighlight
                            autoComplete
                            freeSolo={true}
                            value={dlabel ?? ""}
                            loading={loading}
                            loadingText={"Loading data..."}
                            noOptionsText={"No data found"}
                            options={field}
                            onOpen={() => {
                                setLoading(true);
                                //   console.log('the actionoptions are: ', actionoptions)
                            }}
                            onClose={() => {
                                setLoading(false);
                            }}
                            getOptionLabel={opt => opt.label}
                            getOptionSelected={(opt, val) => opt.label == val.label}
                            onChange={(e, v) => {
                              //  console.log('autocomplete change')
                              //  console.log('v: ', v)
                               // console.log('e: ', e.target.outerText)
                                setDLabel(e.target.outerText)
                                var newSelections = [...selections.slice(0, j), v.value]
                              //  console.log('newselections is: ', newSelections)
                                setFields([...fields.slice(0, j + 1)])
                                setSelections(newSelections)
                                var response = axios(configs[j + 1].asyncConfig(newSelections, credential)).then(resp => {
                                    // console.log(resp)
                                    setFields([...fields, configs[j + 1].responseTransform(resp)])
                                })
                            }}
                            renderOption={(option) => (
                                <MenuItem className={classes.radio} id={option.label} key={option.label} onMouseEnter={() => hoverOn(option.label)}
                                  onMouseLeave={hoverOff} >
                                  <Radio
                                    id={option.label}
                                    key={option.label}
                                    color='primary'
                                    checkedIcon={checkedIcon}
                                    icon={icon}
                                    style={{ marginRight: 8 }}
                                    checked={selected === option.label}
                                    value={option.label}
                                  />
                                  <Typography variant='inherit' noWrap>{option.label}</Typography>
                                </MenuItem>
                              )}
                            renderInput={(params) => <div style={{padding: '8px'}}><TextField {...params} label='Click to Select' variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading === true ? <CircularProgress color='primary' size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }} /></div>}
                        />
                    </>
                )
            } else {
               // console.log('field569: ', field)
               // console.log(field)
                return (
                    <>
                        <FieldsRender key={field} fields={field} setFields={(newField) => {
                            var finalProcessed = configs[j + 1].finalProcess(selections, newField)
                            setFields([...fields.slice(0, j), newField]);
                            submitFunction(finalProcessed)
                        }} />
                        <button style={{border: '1px solid lightgrey', borderRadius: '4px', backgroundColor: 'lightgrey', width:'25%',cursor:'pointer'}}><RefreshRoundedIcon style={{marginLeft:'5px',color:'black', float:'left'}}/><Typography style={{color:'black',padding:'3px 0', fontSize:'12px',fontWeight:600, float:'right', display:'block',marginRight:'3px'}}>Refresh Fields</Typography></button>
                    </>
                )
            }
        }) : null
    )
}

