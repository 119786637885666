import React, {useEffect, useRef} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Handle } from 'react-flow-renderer';
import { Card, ClickAwayListener, Avatar, Badge, Button, Tooltip, Input, InputAdornment, Typography, ListItemIcon, Popper, MenuItem, ListItemText } from '@material-ui/core';
import AppsRoundedIcon from '@material-ui/icons/AppsRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';



import {
  nodeAdded, nodeDuplicated, edgeAdded, elementUpdated, nameUpdated, elementDeleted, fetchWorkflow, nodeEdgeAdded, saveWorkflow, elementSelected,
  selectWorkflowElements, selectElementById, selectWorkflowName, selectWorkflowId, selectParentTree, selectWorkflowTemplate, selectWorkflowActive, selectCurrentElement,
} from '../../features/workflow/workflowSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  highlighted: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginLeft: theme.spacing(0.65)
  },
  large: {
    marginLeft: theme.spacing(3.15),
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  card: {
    position: 'relative',
    width: '450px',
    paddingBottom: '35px',
    cursor: 'pointer',
    height: '50px',
    paddingTop: '9px',
    borderRadius: '4px',
    "& > *:hover": {
      border: '1px solid #0069ea'
    }
  },
  more: {
    zIndex: 99999,

  },
  popper: {
      border: '1px solid lightgray',
      borderRadius: '4px',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      zIndex: 999,
     // height: '100px',
      boxShadow: '0 5px 5px 5px lightgray',
      width: '200px'
  }
}));

const ActionInput = (props) => {
  
  return <Input className='nodrag' startAdornment={
   <InputAdornment position="start">
     <CreateRoundedIcon style={{ color: 'silver' }} />
   </InputAdornment>
 } style={{ marginBottom: '15px', width: "300px", fontSize: '14px', fontWeight: 'bold' }}  onChange={props.onChange} defaultValue={props.value} value={props.value} id="name" placeholder="Name this step..." />
 
 }


const ActionNode = ({ data, id }) => {
  const node = useSelector(selectElementById(id))
    const [name, setName] = React.useState(data.name);
    const [blurname, setBlurName] = React.useState()
    const [app, setApp] = React.useState(data.type);
    const [label, setLabel] = React.useState(data.label);
    const [icon, setIcon] = React.useState(data.icon);
    const [action, setAction] = React.useState(data.action);
    const [actionlabel, setActionLabel] = React.useState(data.actionlabel)
    const [credentialId, setCredentialId] = React.useState(data.credential)
    const [authname, setAuthname] = React.useState(data.authname)
    const [url, setURL] = React.useState(data.url)
    const [actionTestTime, setActionTestTime] = React.useState(data.actionTestTime)
    const [fields, setFields] = React.useState(data.fields)
    const [hasauth, setHasAuth] = React.useState(data.hasauth)
    const [testData, setTestData] = React.useState(data.testData)
  const [renamingstep, setRenameStep] = React.useState(false)
  const [hover, setHover] = React.useState(false)
  const [hover2, setHover2] = React.useState(false)
  const [hover3, setHover3] = React.useState(false)
  const [nodrag, setNoDrag] = React.useState(false)
  //const haloeffect = data.testData ?? "loading"
  const [badge, setBadge] = React.useState()
  const parentTree = useSelector(selectParentTree);
  const parents = parentTree[id]
  const step_number = Object.keys(parents ?? '').length + 1
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentElement = useSelector(selectCurrentElement)

 

  const ErrorBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: 'red',
      color: 'red',
      position: 'absolute',
      boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
      },
  }))(Badge);

  const SuccessBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: 'green',
      color: 'green',
      position: 'absolute',
      boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
      },
  }))(Badge);

  const WarningBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: 'orange',
      color: 'orange',
      position: 'absolute',
      boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
    },
  }))(Badge);

  const openDropdown = (event) => {
    setAnchorElMenu(event.currentTarget);
    setNoDrag(true)
  }

  const saveNode = () => {
    //   console.log('saving node')
    dispatch(elementUpdated(id,
      {...data, name: name },
      null
  ));
  dispatch(saveWorkflow(1))
}
  const handleSaveClick = () => {
    //   console.log('save clicked')
    dispatch(saveWorkflow(1))
  }

  const closeDropdown = (event) => {
    setAnchorElMenu(null);
    setNoDrag(false)
  }
  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Popper className={classes.popper}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ));

  //handling of dropdown menu
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const sourceRef = useRef();
  const removestep = (id) => {
   closeDropdown()
    dispatch(elementDeleted(id))
    handleSaveClick()
  }

  const duplicatestep = (id) => {
   // console.log('node information is: ', node)
  
    closeDropdown()
     dispatch(nodeDuplicated('action', node.position.x + 50, node.position.y + 50, 
     data))
     handleSaveClick()
   }

  const renameStep = (id) => {
    closeDropdown()
    //console.log('do stuff to rename this step: ', id)
    setRenameStep(true)
  }

  useEffect(()=> {
    //here you will have correct value in userInput 
   // console.log('new name is: ', name)
    saveNode()
},[name])

    useEffect(() => {
      if (data.hasauth === true && !data.credential) {
        setBadge('error')
      }

      else if (!data.testData ?? data.testData === "") {
        setBadge('warning')
      }
      else {
        setBadge('ok')
      }
      }, [node])

  return (

    <div id='edit-button-action' className={classes.card} onMouseEnter={() => setHover3(true)} onMouseLeave={() => setHover3(false)} >
      <Card style={{boxShadow: '-1px 5px 5px 1px lightgray',border:  currentElement === id ? '2px solid #0069ea' : null, backgroundColor:  currentElement === id ? '#e9f4fd' : null}} className={nodrag === true ? "nodrag" : null} >
      { badge === 'error' ? 
      <ErrorBadge
      style={{float: 'left',marginTop: '20px', marginLeft:'9px'}}
      overlap="circle"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      variant="dot"
    >
     <Avatar  style={{display: 'flex', float: 'left',marginTop: '0px', marginLeft: '0px',height:50, width:50, boxShadow: '1px 5px 5px 0px silver'}} label={data.name} src={data.icon} alt={data.name}>
     <AppsRoundedIcon style={ {width : 40,height:40 } }  />
     </Avatar>
     </ErrorBadge>
    : badge === 'warning' ? 
    <WarningBadge
      style={{float: 'left',marginTop: '20px', marginLeft:'9px'}}
      overlap="circle"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      variant="dot"
    >
     <Avatar  style={{display: 'flex', float: 'left',marginTop: '0px', marginLeft: '0px',height:50, width:50, boxShadow: '1px 5px 5px 0px silver'}} label={data.name} src={data.icon} alt={data.name}>
     <AppsRoundedIcon style={ {width : 40,height:40 } }  />
     </Avatar>
     </WarningBadge> :
    <SuccessBadge
    style={{float: 'left',marginTop: '20px', marginLeft:'9px'}}
    overlap="circle"
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    variant="dot"
  >
   <Avatar  style={{display: 'flex', float: 'left',marginTop: '0px', marginLeft: '0px', height:50, width:50, boxShadow: '1px 5px 5px 0px silver'}} label={data.name} src={data.icon} alt={data.name}>
   <AppsRoundedIcon style={ {width : 40,height:40 } }  />
   </Avatar>
   </SuccessBadge>}
   
        <div style={{ display: 'block', float: 'left', marginLeft: '10px' }}>
          <Typography style={{ fontSize: '14px', color: 'gray', marginTop: '20px' }}>Action</Typography>
          
            { renamingstep ? <div onBlur={()=>{ setRenameStep(false);setName(blurname); handleSaveClick()}}><ActionInput onChange={e => { setBlurName(e.target.value); }} defaultValue={node ? node.data.name : data.name} value={blurname}  /></div>
          
            :
             <Typography noWrap={true} style={{display: 'block', marginTop: '0px', marginBottom: '20px', width: '250px', fontSize: '20px', fontWeight: 'bolder' }} >{step_number + ". "} {node ? node.data.name : data.name}</Typography> }
            
        </div>
        
        <div id='edit-button-action' style={{position: 'absolute',display:'flex', float:'right', marginTop: '30px', right: 50}} >
        {hover3 === true ? <Button variant="contained" color="primary" >Edit</Button> : null}
        </div>
        <ClickAwayListener onClickAway={closeDropdown}>
        
        <div className="nodrag" style={{ cursor: 'pointer', float: 'right', marginRight: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '45px' }}>
          <MoreVertRoundedIcon style={{ position: 'absolute', float: 'right', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={openDropdown} />
          
          <StyledMenu 
            id="customized-menu"
            anchorEl={anchorElMenu}
            
            open={Boolean(anchorElMenu)}
            onClose={closeDropdown}
          >
            <MenuItem className="nodrag"  onClick={() => renameStep(node.id) }>
              <ListItemIcon>
                <EditRoundedIcon fontSize="small" color="black" />
              </ListItemIcon>
              <ListItemText style={{ marginRight: 20 }} primary="Rename Step" />
            </MenuItem>
            <MenuItem className="nodrag" onClick={() => duplicatestep(node.id)}>
              <ListItemIcon>
                <FileCopyOutlinedIcon fontSize="small" color="black" />
              </ListItemIcon>
              <ListItemText style={{ marginRight: 20 }} primary="Duplicate" />
            </MenuItem>
            <MenuItem className="nodrag" onClick={() => removestep(node.id)}>
              <ListItemIcon>
                <DeleteForeverRoundedIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <ListItemText style={{ marginRight: 20 }} primary="Delete Step" />
            </MenuItem>
          </StyledMenu>
        </div>
        </ClickAwayListener>
      </Card>

      <Handle style={hover === true ? {
        backgroundColor: 'white',
        marginBottom: '20px',
        height: 20,
        width: 20,
        borderRadius: 50,
        border: '1.5px solid gray',
        boxShadow: '0 3px 5px 2px lightgray',
        backgroundPosition: 'center center', backgroundImage: "url('/circle.svg')"
      } : {
        backgroundColor: 'white',
        marginBottom: '20px',
        height: 20,
        width: 20,
        borderRadius: 50,
        border: '1px solid gray',
        backgroundPosition: 'center center', backgroundImage: "url('/circle.svg')"
      }}
        type="target" position="top"
        // isConnectable={false}
        onMouseEnter={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      />
      <Tooltip title="Add Action Step" placement="right">
        <Handle style={hover2 === true ? {
          backgroundColor: 'white',
          marginBottom: '-15px',
          height: 20,
          width: 20,
          borderRadius: 50,
          border: '1.5px solid gray',
          boxShadow: '0 3px 5px 2px lightgray',
          backgroundPosition: 'center center', backgroundImage: "url('/add-circle.svg')"
        } : {
          backgroundColor: 'white',
          marginBottom: '-15px',
          height: 20,
          width: 20,
          borderRadius: 50,
          border: '1px solid gray',
          backgroundPosition: 'center center', backgroundImage: "url('/add-circle.svg')"
        }}
          type="source"
          position="bottom"
          id="a"
          onMouseEnter={() => setHover2(true)}
          onMouseOut={() => setHover2(false)}
        />
      </Tooltip>

    </div>

  );
};

export default ActionNode;